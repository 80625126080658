export const LOGIN_URL = "/login";
export const PATIENT_URL = "/patient/get";
export const PATIENT_SEARCH = "/patient/search";
export const PATIENT_DETAILS = "/patient/details";
export const PATIENT_DELETE = "/patient/delete";
export const PATIENT_UPDATE = "/patient/update";
export const PATIENT_NEW = "/patient/add";
export const VISIT_LIST = "/visit/get";
export const VISIT_DELETE = "/visit/delete";
export const VISIT_ADD = "/visit/add";
export const MEDICINE_SEARCH = "/search/medicine";
export const REPORT_SEARCH = "/search/report";
export const ANALYTICS_DETAILS = "/analytics/patient";
export const APPOINTMENT_ANALYTICS = "/analytics/appointment";
export const MEDICINE_ANALYTICS = "/analytics/medicine";
export const REPORT_ANALYTICS = "/analytics/report";
export const CREATE_MEDICINE = "/medicine/add";
export const MEDICINE_REMINDER_ANALYTICS = "/analytics/appointment_med";
export const TEST_REPORTS = "/analytics/appointment_report";

export const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
