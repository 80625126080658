import { useState, useEffect } from "react";
import Topnav from "./Topnav";
import Login from "./Login";
import { useAuth } from "../store/auth.js";
import Dashboard from "./Dashboard";

export default function Layout() {
  const userId = useAuth((state) => state.userId);

  return (
    <>
      <Topnav />
      {userId !== 0 ? <Dashboard /> : <Login />}
    </>
  );
}
