import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { useAuth } from "../store/auth.js";

export default function Topnav() {
  const [openNav, setOpenNav] = useState(false);
  const userId = useAuth((state) => state.userId);
  const resetAuth = useAuth((state) => state.resetAuth);

  function logoutUser() {
    if (userId !== 0) resetAuth();
  }

  //   const navList = (
  //     <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
  //       <Typography
  //         as="li"
  //         variant="small"
  //         color="blue-gray"
  //         className="p-1 font-normal"
  //       >
  //         <a href="#" className="flex items-center">
  //           Patients
  //         </a>
  //       </Typography>
  //       <Typography
  //         as="li"
  //         variant="small"
  //         color="blue-gray"
  //         className="p-1 font-normal"
  //       >
  //         <a href="#" className="flex items-center">
  //           Visits
  //         </a>
  //       </Typography>
  //     </ul>
  //   );
  const navList = "";
  return (
    <Navbar className="mx-auto max-w-screen-xl py-2 px-4 lg:px-8 lg:py-4">
      <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="#"
          className="mr-4 cursor-pointer py-1.5 font-medium"
        >
          <img
            className="hidden h-10 w-auto lg:block md:block sm:block"
            src={window.location.origin + "/logo-transparent.png"}
            alt="CareDose"
            // onClick={goHome}
          />
        </Typography>
        {userId !== 0 ? (
          <div className="hidden lg:block md:block sm:block">{navList}</div>
        ) : (
          <span>Welcome!</span>
        )}
        <Button
          variant="gradient"
          size="sm"
          className="hidden lg:inline-block md:inline-block sm:inline-block"
          onClick={logoutUser}
        >
          <span>{userId !== 0 ? "Logout" : "Login"}</span>
        </Button>
      </div>
    </Navbar>
  );
}
