import { useEffect, useState } from "react";
import axios from "../api/axios";
import {
  ANALYTICS_DETAILS,
  MEDICINE_ANALYTICS,
  REPORT_ANALYTICS,
} from "../constants";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../store/auth.js";
import { result } from "lodash";
import { Input, Button } from "@material-tailwind/react";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import AnalyticsDocument from "./AnalyticsDocument";

export default function ViewAnalyticsDetails() {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const [analyticsPatientData, setAnalyticsPatientData] = useState({});
  const [analyticsVisitData, setAnalyticsVisitData] = useState({});
  const [analyticsVisitCount, setAnalyticsVisitCount] = useState({});
  const [medicineAnalytics, setMedicineAnalytics] = useState([]);
  const [reportAnalytics, setReportAnalytics] = useState([]);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const today = new Date();
  const currentDate = new Date().toISOString().split("T")[0];
  let tomorrowDate = new Date();
  tomorrowDate.setDate(today.getDate() + 1);
  const [dateData, setDateData] = useState({
    from: currentDate,
    to: currentDate,
    // to: tomorrowDate.toISOString().split("T")[0],
  });
  const [pdfBlob, setPdfBlob] = useState(false);

  const downloadPdf = async () => {
    const pdfInstance = pdf(
      <AnalyticsDocument
        analytics={{
          dateData,
          analyticsPatientData,
          analyticsVisitData,
          analyticsVisitCount,
          medicineAnalytics,
          reportAnalytics,
        }}
      />
    );
    const pdfBlob = await pdfInstance.toBlob();
    setPdfBlob(pdfBlob);
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      const currentDate = new Date();
      const fileName = `dailyAnalytics_${currentDate.toLocaleString()}.pdf`;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    }
  };
  const handleChange = (event) => {
    let name = event.target.name;
    const value = event.target.value;
    setDateData((values) => ({ ...values, [name]: value }));
  };
  const getAnalyticsDetails = async () => {
    try {
      if (token !== "" && userId !== 0) {
        const response = await axios.get(ANALYTICS_DETAILS, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { from: dateData.from, to: dateData.to },
        });
        if (response.status === 200) {
          setAnalyticsPatientData(response.data["patients"]);
          setAnalyticsVisitData(response.data["visits"]);
          setAnalyticsVisitCount(response.data["visit_count"]);
        } else {
          alert("No server response [1]");
        }
        console.log("patients:", response);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      } else {
        alert("No server response [2]");
      }
    }
  };
  const getMedicineAnalytics = async () => {
    try {
      if (token !== "" && userId !== 0) {
        const response = await axios.get(MEDICINE_ANALYTICS, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { from: dateData.from, to: dateData.to },
        });
        // console.log(response);
        if (response.status === 200) {
          setMedicineAnalytics(response.data["medicine_data"]);
        } else {
          alert("No server response [3]");
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      } else {
        alert("No server response [4]");
      }
    }
  };

  const getReportAnalytics = async () => {
    try {
      if (token !== "" && userId !== 0) {
        const response = await axios.get(REPORT_ANALYTICS, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { from: dateData.from, to: dateData.to },
        });
        console.log(response);
        if (response.status === 200) {
          setReportAnalytics(response.data["master_report_data"]);
        } else {
          alert("No server response [5]");
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      } else {
        alert("No server response [6]");
      }
    }
  };
  const analyticsDetails = () => {
    getAnalyticsDetails();
    getMedicineAnalytics();
    getReportAnalytics();
  };

  return (
    <>
      <div className="flex flex-col gap-2 w-60 text-center sm:ml-60 md:ml-72 lg:ml-[420px]">
        <Input
          label="From"
          type="date"
          pattern="\d{4}-\d{2}-\d{2}"
          name="from"
          value={dateData.from}
          onChange={handleChange}
        />
        <Input
          label="To"
          type="date"
          pattern="\d{4}-\d{2}-\d{2}"
          name="to"
          value={dateData.to}
          onChange={handleChange}
        />
        <div className="flex flex-row">
          <Button onClick={analyticsDetails} className="w-16">
            <span className=" text-center">Go</span>
          </Button>
          <Button className="bg-gray-700 w-16 ml-[113px]" onClick={downloadPdf}>
            <span>
              <FontAwesomeIcon icon={faDownload} size="lg" />
            </span>
          </Button>
        </div>
      </div>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Patient Analytics</caption>
        <tbody>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Total Patients
            </td>
          </tr>
          <tr>
            <td className="border border-slate-600 text-center">
              {analyticsPatientData.total_count}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Visit Analytics</caption>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td className="text-center font-bold">
              <p className="ml-[120px] font-bold">Real Visit Analytics</p>
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="border border-slate-600 text-center font-bold">
              Review Visits
            </td>
            <td className="border border-slate-600 text-center font-bold">
              New Visits
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Old Visits
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Visits Count
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="border border-slate-600 text-center">
              {analyticsVisitData.review_count}
            </td>
            <td className="border border-slate-600 text-center">
              {analyticsVisitData.new_visits}
            </td>
            <td className="border border-slate-600 text-center">
              {analyticsVisitData.old_visits}
            </td>
            <td className="border border-slate-600 text-center">
              {analyticsVisitData.real_visit_count}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td className="text-center font-bold">
              <p className="ml-[110px] font-bold">System Visit Analytics</p>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              With Medicine
            </td>
            <td className="border border-slate-600 text-center font-bold">
              With Reports
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Visit Count
            </td>
            <td className="border border-slate-600 text-center font-bold">
              With Meds-Reports
            </td>
            <td className="border border-slate-600 text-center font-bold">
              With No Med No Reports
            </td>
          </tr>
          <tr>
            <td className="border border-slate-600 text-center">
              {analyticsVisitCount.meds_count}
            </td>
            <td className="border border-slate-600 text-center">
              {analyticsVisitCount.reports_count}
            </td>
            <td className="border border-slate-600 text-center">
              {analyticsVisitCount.system_visit_count}
            </td>
            <td className="border border-slate-600 text-center">
              {analyticsVisitCount.common_count}
            </td>
            <td className="border border-slate-600 text-center">
              {analyticsVisitCount.count_no_meds_reports}
            </td>
          </tr>
        </tbody>
      </table>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Medicine Analytics</caption>
        <tbody>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Medicine Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Medicine Count
            </td>
          </tr>
          {medicineAnalytics.map((result, index) => (
            <tr className="border border-slate-600 text-center" key={index}>
              <td className="border border-slate-600 text-center">
                {index + 1}
              </td>
              <td>{result.medicine_name}</td>
              <td className="border border-slate-600 text-center">
                {result.medicine_count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Report Analytics</caption>
        <tbody>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Count
            </td>
          </tr>
          {reportAnalytics.map((result, index) => (
            <tr className="border border-slate-600 text-center" key={index}>
              <td className="border border-slate-600 text-center">
                {index + 1}
              </td>
              <td>{result.report_name}</td>
              <td className="border border-slate-600 text-center">
                {result.report_count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
