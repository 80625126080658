import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Typography,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import { PATIENT_URL, PATIENT_DELETE } from "../constants";
import { useAuth } from "../store/auth.js";
import { useDash, useVisit } from "../store/dashboard";
import axios from "../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTrashCan,
  faCalendarCheck,
  faCirclePlus,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";

const TABLE_HEAD = ["Sr", "Id", "Name", "Mobile", "Sex", "Actions"];

export default function Patient() {
  const token = useAuth((state) => state.token);
  const setPatients = useDash((state) => state.setPatients);
  const userId = useAuth((state) => state.userId);
  const patients = useDash((state) => state.patients);
  const searchTrigger = useDash((state) => state.searchTrigger);
  const setTrigger = useDash((state) => state.setTrigger);
  const setPatientId = useDash((state) => state.setPatientId);
  const setPatientModal = useDash((state) => state.setPatientModal);
  const setAddPatient = useDash((state) => state.setAddPatient);
  const setVisitPatientId = useVisit((state) => state.setVisitPatientId);
  const setVisitModal = useVisit((state) => state.setVisitModal);
  const setVisitDetailsState = useVisit((state) => state.setVisitDetailsState);
  const setAddVisit = useVisit((state) => state.setAddVisit);
  const resetAuth = useAuth((state) => state.resetAuth);

  const getPatients = async () => {
    try {
      if (token !== "" && userId !== 0) {
        const response = await axios.get(PATIENT_URL, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
        });
        // console.log(response);
        if (response.status === 200) {
          setPatients(response.data["patients"]);
        } else {
          alert("Failed to fetch patients [1]");
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else if (err.response.status === 401) {
        alert("Session Expired");
        resetAuth();
      } else {
        alert("Failed to fetch patients [2]");
      }
    }
  };
  const handleDelete = async (id, name) => {
    let text = `Are you sure you want to delete :${name}`;
    if (window.confirm(text) == true) {
      try {
        if (token !== "" && userId !== 0) {
          const response = await axios.post(
            PATIENT_DELETE,
            { id: id },
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-tokens": token,
              },
            }
          );
          // console.log(response);
          if (response.status === 200) {
            setTrigger();
          } else {
            alert("Failed to delete patients [1]");
          }
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          alert(err?.response?.data?.message);
        } else {
          alert("Failed to delete patients [2]");
        }
      }
    }
  };
  const handleLabelPrint = (record) => {
    let name = record.fname + " " + record.lname;
    // let age = Math.floor(
    //   (new Date() - new Date(record.birth_date).getTime()) / 3.15576e10
    // );
    let age = record.age;
    let gender =
      record.sex === "M" ? "Male" : record.sex === "F" ? "Female" : "Trans";
    let address = record?.address?.city || "";
    let uid = `N${record.id}`;
    // console.log(name, age, uid, address, gender);
    window.open(
      `https://label.caredose.com/result_patient_label?name=${name}&age=${age}&gender=${gender}&add=${address}&uid=${uid}`
    );
  };
  useEffect(() => {
    getPatients();
  }, [userId, searchTrigger]);

  return (
    <div className="w-full px-10 py-5 overscroll-y-auto">
      <Card className="overflow-scroll h-full w-full">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {patients.map(
              (
                { id, fname, lname, mobile, age, sex, address, birth_date },
                index
              ) => {
                const isLast = index === patients.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={index} className="hover:bg-gray-200">
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {index + 1}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {id}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold"
                      >
                        {fname + " " + lname}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {mobile}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {sex}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        as="a"
                        href="#"
                        variant="small"
                        color="blue"
                        className="font-medium w-full "
                      >
                        <div className="flex flex-row gap-5 justify-center">
                          <Tooltip content="Add visit">
                            <IconButton
                              color="white"
                              onClick={() => {
                                setVisitPatientId(id);
                                setVisitDetailsState(true);
                                setAddVisit(true);
                              }}
                              ripple={false}
                            >
                              <FontAwesomeIcon icon={faCirclePlus} size="2x" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content="View patient data">
                            <IconButton
                              color="white"
                              onClick={() => {
                                setPatientModal(true);
                                setAddPatient(false);
                                setPatientId(id);
                              }}
                              ripple={false}
                            >
                              <FontAwesomeIcon icon={faEye} size="2x" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content="View visits">
                            <IconButton
                              color="white"
                              onClick={() => {
                                setVisitPatientId(id);
                                setVisitModal(true);
                              }}
                              ripple={false}
                            >
                              <FontAwesomeIcon
                                icon={faCalendarCheck}
                                size="2x"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content="Delete patient">
                            <IconButton
                              color="white"
                              onClick={() => {
                                handleDelete(id, `${fname} ${lname}`);
                              }}
                              ripple={false}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                size="2x"
                                color="orange"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content="Print Sticker">
                            <IconButton
                              color="white"
                              onClick={() => {
                                handleLabelPrint({
                                  id,
                                  fname,
                                  lname,
                                  mobile,
                                  age,
                                  sex,
                                  address,
                                  birth_date,
                                });
                              }}
                              ripple={false}
                            >
                              <FontAwesomeIcon
                                icon={faPrint}
                                size="2x"
                                color="black"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Typography>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
        {patients.length > 0 ? (
          <></>
        ) : (
          <Card className="mt-6 w-full text-center">
            <CardBody>
              <Typography variant="h4" color="blue-gray" className="mb-2">
                No records found
              </Typography>
            </CardBody>
          </Card>
        )}
      </Card>
    </div>
  );
}
