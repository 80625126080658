import {
  Card,
  CardBody,
  Typography,
  Tooltip,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
  Button,
  Input,
  Select,
  Option,
  List,
  ListItem,
  ListItemSuffix,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowRight,
  faSquarePlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDash, useVisit } from "../store/dashboard";
import { useAuth } from "../store/auth.js";
import { MEDICINE_SEARCH, CREATE_MEDICINE } from "../constants";
import axios from "../api/axios";

export default function CreateMedicine() {
  const setMedicineModal = useDash((state) => state.setMedicineModal);
  const medicineModal = useDash((state) => state.medicineModal);

  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);

  const [qs, setQs] = useState("");
  const [searchList, setSearchList] = useState([]);

  const [medicineData, setMedicineData] = useState({});
  const [err, setErr] = useState([]);
  const reqArr = ["name", "unit", "company"];

  const resetModal = () => {
    setMedicineData({});
  };

  const handleChange = (event) => {
    let name = event.target.name;
    const value = event.target.value;
    setMedicineData((values) => ({ ...values, [name]: value }));
    if (reqArr.includes(event.target.name)) {
      if (event.target.value.trim() === "") {
        if (![...err].includes(event.target.name)) {
          setErr([...err, event.target.name]);
        }
      } else {
        setErr(err.filter((item) => item !== event.target.name));
      }
    }
    if (event.target.value.trim() !== "" && err.includes(event.target.name)) {
      setErr(err.filter((item) => item !== event.target.name));
    }
  };

  const handleCreateMedicine = async (e) => {
    try {
      e.preventDefault();
      let reqData = { ...medicineData };

      const checkAllFields = Object.keys(reqData);

      const allFieldsPresent = reqArr.every((fieldName) =>
        checkAllFields.includes(fieldName)
      );
      if (!allFieldsPresent) {
        alert("please fill all the required fields");
      } else {
        if (token !== "" && userId !== 0) {
          const response = await axios.post(CREATE_MEDICINE, reqData, {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": token,
            },
          });
          if (response.status == 200) {
            alert("MEDICINE CREATED");
            setMedicineData({ name: "", unit: "", company: "", code: "" });
            setErr(reqArr);
          } else {
            alert("Failed to create medicine[1]");
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
        console.log(err);
      } else {
        alert("Failed to create medicine[2]");
      }
    }
  };

  const handleSearch = async () => {
    try {
      if (token !== "" && qs !== "") {
        const response = await axios.get(MEDICINE_SEARCH, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { q: qs },
        });
        console.log(response);
        if (response.status === 200) {
          setSearchList(response.data["medicines"]);
        } else {
          alert("Failed to fetch medicines[1]");
        }
      } else if (token !== "" && qs === "") {
        setSearchList([]);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else {
        alert("Failed to fetch medicines[2]");
      }
    }
  };
  useEffect(() => {
    setErr(reqArr);
    return () => {
      setSearchList([]);
      setQs();
      setMedicineData({});
    };
  }, [medicineModal]);
  return (
    <>
      <Dialog open={medicineModal} handler={setMedicineModal}>
        <DialogHeader>
          <span className="w-full text-center">Medicines</span>
        </DialogHeader>
        <DialogBody
          divider
          className="max-h-[45rem] overflow-scroll py-10 sm:h-[30rem] md:h-[35rem] lg:h-[50rem]"
        >
          <div className="border-2 border-gray-300 border-solid rounded-lg px-5 py-5">
            <div className="flex flex-row gap-1">
              <Input
                label={`Search Medicines`}
                icon={<FontAwesomeIcon icon={faSearch} />}
                onChange={(e) => setQs(e.target.value)}
                value={qs}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <IconButton onClick={handleSearch}>
                <FontAwesomeIcon icon={faArrowRight} size="2x" />
              </IconButton>
            </div>
            <List>
              {searchList.map((result, index) => (
                <ListItem key={index}>
                  {result.name}
                  <ListItemSuffix>
                    <FontAwesomeIcon size="lg" />
                  </ListItemSuffix>
                </ListItem>
              ))}
            </List>
          </div>
          <div className="w-full text-center">
            <Typography className="w-full text-center pt-5" variant="h5">
              Create Medicines
            </Typography>
            <form className="mt-8 mb-2 px-10" onSubmit={handleCreateMedicine}>
              <div className="mb-4 flex flex-col lg:gap-4 md:gap-3 sm:gap-1">
                <Input
                  size="lg"
                  label="Medicine Name"
                  required
                  onChange={handleChange}
                  value={medicineData.name}
                  name="name"
                  error={err.includes("name")}
                />
                <Input
                  size="lg"
                  label="Unit"
                  required
                  onChange={handleChange}
                  value={medicineData.unit}
                  name="unit"
                  error={err.includes("unit")}
                />
                <Input
                  size="lg"
                  label="Company"
                  required
                  onChange={handleChange}
                  value={medicineData.company}
                  name="company"
                  error={err.includes("company")}
                />
                <Input
                  size="lg"
                  label="Code"
                  onChange={handleChange}
                  value={medicineData.code}
                  name="code"
                />
                <Button type="submit">Create</Button>
              </div>
            </form>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => {
              setMedicineModal(false);
            }}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
