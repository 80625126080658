import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useAuth = create(
  persist(
    (set) => ({
      user: "",
      userId: 0,
      token: "",
      expiration: 0,
      unitId: 0,
      unitName: "",
      setUser: (user) => set({ user }),
      setUserId: (userId) => set({ userId }),
      setToken: (token) => set({ token }),
      setUnitId: (unitId) => set({ unitId }),
      setUnitName: (unitName) => set({ unitName }),
      resetAuth: () =>
        set({
          user: "",
          userId: 0,
          token: "",
          expiration: 0,
          unitId: 0,
          unitName: "",
        }),
    }),
    {
      name: "auth-storage", // name of the item in the storage (must be unique)
      // storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      partialize: (state) => ({ userId: state.userId, token: state.token }),
      version: 1,
    }
  )
);
