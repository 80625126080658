import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import ViewAnalyticsDetails from "./ViewAnalyticsDetails";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingBottom: 30,
    paddingTop: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderStyle: "solid",
  },
  tableTestReport: {
    display: "table",
    width: "800",
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    padding: 2,
    textAlign: "center",
  },
  tableColMinWidth: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 0.5,
    padding: 2,
    textAlign: "center",
  },
  tableColSrno: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0.5,
    padding: 2,
    textAlign: "center",
  },
  tableCell: {
    width: "100%",
    fontSize: 10,
    flexDirection: "col",
    padding: 2,
    textAlign: "center",
  },
  tableCellOverflow: {
    width: "100%",
    fontSize: 10,
    flexDirection: "col",
    padding: 2,
    textAlign: "center",
    maxHeight: 40,
  },
  tableCellOverflowLab: {
    width: "80%",
    fontSize: 10,
    flexDirection: "col",
    padding: 2,
    textAlign: "center",
    maxHeight: 35,
  },
  tableCellMinWidth: {
    width: "40%",
    fontSize: 10,
    flexDirection: "col",
    padding: 2,
    textAlign: "center",
  },
  tableCellItalic: {
    width: "100%",
    fontFamily: "Times-Italic",
    fontWeight: "bold",
    fontSize: 10,
    flexDirection: "col",
    padding: 2,
    textAlign: "center",
  },
  tableCellItalicMinWidth: {
    width: "40%",
    fontFamily: "Times-Italic",
    fontWeight: "bold",
    fontSize: 10,
    flexDirection: "col",
    padding: 2,
    textAlign: "center",
  },
  heading: {
    width: 200,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 30,
    marginLeft: 328,
  },
  hospitalhead: {
    width: 200,
    fontWeight: "bold",
    marginLeft: 320,
  },
  subheadingFrequency: {
    fontWeight: "extrabold",
    marginTop: "1%",
    marginBottom: 5,
    marginLeft: 390,
  },
  subheadingFrequency2: {
    fontWeight: "extrabold",
    marginTop: "1%",
    marginBottom: 5,
    marginLeft: 345,
  },
  subheadingFrequency4: {
    fontWeight: "extrabold",
    marginTop: "1%",
    marginBottom: 5,
    marginLeft: 385,
  },
  rangeDate: {
    width: 300,
    borderStyle: "solid",
    marginBottom: 35,
    fontSize: 15,
    fontWeight: "normal",
  },
  date: {
    width: 200,
    borderStyle: "solid",
    marginBottom: 45,
    fontSize: 15,
    marginLeft: 615,
    fontWeight: "normal",
  },
  image: {
    height: 30,
    width: 100,
  },
});

const TestReportDocument = ({ testreports }) => {
  const currentDate = new Date();
  const imageData = "logo.jpg";
  const calculateSrno = (data, indexMain, indexSub) => {
    // console.log(indexMain, indexSub);
    let count = 0;
    if (indexMain <= 0) {
      return indexSub + 1;
    } else {
      for (var i = 0; i < indexMain; i++) {
        count = count + data[i].length;
        if (i === indexMain - 1) {
          return count + indexSub + 1;
        }
      }
    }
  };
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page} wrap={true} orientation="landscape">
          <View style={styles.section}>
            <Image style={styles.image} src={imageData} />
            <Text style={styles.hospitalhead}>Shri. Vankatesh Hospital</Text>
            <Text style={styles.heading}>Test Report Analytics</Text>
            <Text style={styles.date}>{currentDate.toLocaleString()}</Text>
            <Text style={styles.rangeDate}>
              Range: From: {testreports.dateData.from} - To:{" "}
              {testreports.dateData.to}
            </Text>
            <Text style={styles.subheadingFrequency}>Daily</Text>
            <View style={styles.tableTestReport}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Reminder Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Lab</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Frequency</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Initial Test Date</Text>
                </View>
              </View>
              {testreports.testReportAnalytics["1"].map((result, index) => (
                <>
                  {result.map((res, ind) => (
                    <View
                      style={styles.tableRow}
                      key={calculateSrno(
                        testreports.testReportAnalytics["1"],
                        index,
                        ind
                      )}
                    >
                      <View style={styles.tableColSrno}>
                        <Text style={styles.tableCell}>
                          {calculateSrno(
                            testreports.testReportAnalytics["1"],
                            index,
                            ind
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.fname}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.lname}</Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>{res.sex}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.mobile}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.city}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {res.next_visit_date?.substring(0, 10)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellItalic}>
                          {res.reminder_date}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflow}>
                          {res.report_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflowLab}>
                          {res.lab}
                        </Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>D</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.test_date}</Text>
                      </View>
                    </View>
                  ))}
                </>
              ))}
            </View>

            <Text style={styles.subheadingFrequency2}>Every Other Day</Text>
            <View style={styles.tableTestReport}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Reminder Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Lab</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Frequency</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Initial Test Date</Text>
                </View>
              </View>
              {testreports.testReportAnalytics["2"].map((result, index) => (
                <>
                  {result.map((res, ind) => (
                    <View
                      style={styles.tableRow}
                      key={calculateSrno(
                        testreports.testReportAnalytics["2"],
                        index,
                        ind
                      )}
                    >
                      <View style={styles.tableColSrno}>
                        <Text style={styles.tableCell}>
                          {" "}
                          {calculateSrno(
                            testreports.testReportAnalytics["2"],
                            index,
                            ind
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.fname}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.lname}</Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>{res.sex}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.mobile}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.city}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {res.next_visit_date?.substring(0, 10)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellItalic}>
                          {res.reminder_date}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflow}>
                          {res.report_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflowLab}>
                          {res.lab}
                        </Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>ED</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.test_date}</Text>
                      </View>
                    </View>
                  ))}
                </>
              ))}
            </View>

            <Text style={styles.subheadingFrequency}>Weekly</Text>
            <View style={styles.tableTestReport}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Reminder Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Lab</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Frequency</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Initial Test Date</Text>
                </View>
              </View>
              {testreports.testReportAnalytics["3"].map((result, index) => (
                <>
                  {result.map((res, ind) => (
                    <View
                      style={styles.tableRow}
                      key={calculateSrno(
                        testreports.testReportAnalytics["3"],
                        index,
                        ind
                      )}
                    >
                      <View style={styles.tableColSrno}>
                        <Text style={styles.tableCell}>
                          {" "}
                          {calculateSrno(
                            testreports.testReportAnalytics["3"],
                            index,
                            ind
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.fname}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.lname}</Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>{res.sex}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.mobile}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.city}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {res.next_visit_date?.substring(0, 10)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellItalic}>
                          {res.reminder_date}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflow}>
                          {res.report_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflowLab}>
                          {res.lab}
                        </Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>W</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.test_date}</Text>
                      </View>
                    </View>
                  ))}
                </>
              ))}
            </View>

            <Text style={styles.subheadingFrequency4}>Bi Weekly</Text>
            <View style={styles.tableTestReport}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Reminder Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Lab</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Frequency</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Initial Test Date</Text>
                </View>
              </View>
              {testreports.testReportAnalytics["4"].map((result, index) => (
                <>
                  {result.map((res, ind) => (
                    <View
                      style={styles.tableRow}
                      key={calculateSrno(
                        testreports.testReportAnalytics["4"],
                        index,
                        ind
                      )}
                    >
                      <View style={styles.tableColSrno}>
                        <Text style={styles.tableCell}>
                          {" "}
                          {calculateSrno(
                            testreports.testReportAnalytics["4"],
                            index,
                            ind
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.fname}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.lname}</Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>{res.sex}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.mobile}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.city}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {res.next_visit_date?.substring(0, 10)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellItalic}>
                          {res.reminder_date}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflow}>
                          {res.report_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflowLab}>
                          {res.lab}
                        </Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>BW</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.test_date}</Text>
                      </View>
                    </View>
                  ))}
                </>
              ))}
            </View>

            <Text style={styles.subheadingFrequency}>Monthly</Text>
            <View style={styles.tableTestReport}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Reminder Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Lab</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Frequency</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Initial Test Date</Text>
                </View>
              </View>
              {testreports.testReportAnalytics["5"].map((result, index) => (
                <>
                  {result.map((res, ind) => (
                    <View
                      style={styles.tableRow}
                      key={calculateSrno(
                        testreports.testReportAnalytics["5"],
                        index,
                        ind
                      )}
                    >
                      <View style={styles.tableColSrno}>
                        <Text style={styles.tableCell}>
                          {" "}
                          {calculateSrno(
                            testreports.testReportAnalytics["5"],
                            index,
                            ind
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.fname}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.lname}</Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>{res.sex}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.mobile}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.city}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {res.next_visit_date?.substring(0, 10)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellItalic}>
                          {res.reminder_date}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflow}>
                          {res.report_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflowLab}>
                          {res.lab}
                        </Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>M</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.test_date}</Text>
                      </View>
                    </View>
                  ))}
                </>
              ))}
            </View>

            <Text style={styles.subheadingFrequency4}>Bi Monthly</Text>
            <View style={styles.tableTestReport}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Reminder Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Lab</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Frequency</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Initial Test Date</Text>
                </View>
              </View>
              {testreports.testReportAnalytics["6"].map((result, index) => (
                <>
                  {result.map((res, ind) => (
                    <View
                      style={styles.tableRow}
                      key={calculateSrno(
                        testreports.testReportAnalytics["6"],
                        index,
                        ind
                      )}
                    >
                      <View style={styles.tableColSrno}>
                        <Text style={styles.tableCell}>
                          {" "}
                          {calculateSrno(
                            testreports.testReportAnalytics["6"],
                            index,
                            ind
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.fname}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.lname}</Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>{res.sex}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.mobile}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.city}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {res.next_visit_date?.substring(0, 10)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellItalic}>
                          {res.reminder_date}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflow}>
                          {res.report_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflowLab}>
                          {res.lab}
                        </Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>BM</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.test_date}</Text>
                      </View>
                    </View>
                  ))}
                </>
              ))}
            </View>

            <Text style={styles.subheadingFrequency}>3 Months</Text>
            <View style={styles.tableTestReport}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Reminder Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Lab</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Frequency</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Initial Test Date</Text>
                </View>
              </View>
              {testreports.testReportAnalytics["7"].map((result, index) => (
                <>
                  {result.map((res, ind) => (
                    <View
                      style={styles.tableRow}
                      key={calculateSrno(
                        testreports.testReportAnalytics["7"],
                        index,
                        ind
                      )}
                    >
                      <View style={styles.tableColSrno}>
                        <Text style={styles.tableCell}>
                          {" "}
                          {calculateSrno(
                            testreports.testReportAnalytics["7"],
                            index,
                            ind
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.fname}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.lname}</Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>{res.sex}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.mobile}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.city}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {res.next_visit_date?.substring(0, 10)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellItalic}>
                          {res.reminder_date}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflow}>
                          {res.report_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflowLab}>
                          {res.lab}
                        </Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>3M</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.test_date}</Text>
                      </View>
                    </View>
                  ))}
                </>
              ))}
            </View>

            <Text style={styles.subheadingFrequency}>6 Months</Text>
            <View style={styles.tableTestReport}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Reminder Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Lab</Text>
                </View>
                <View style={styles.tableColMinWidth}>
                  <Text style={styles.tableCellItalicMinWidth}>Frequency</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Initial Test Date</Text>
                </View>
              </View>
              {testreports.testReportAnalytics["8"].map((result, index) => (
                <>
                  {result.map((res, ind) => (
                    <View
                      style={styles.tableRow}
                      key={calculateSrno(
                        testreports.testReportAnalytics["8"],
                        index,
                        ind
                      )}
                    >
                      <View style={styles.tableColSrno}>
                        <Text style={styles.tableCell}>
                          {" "}
                          {calculateSrno(
                            testreports.testReportAnalytics["8"],
                            index,
                            ind
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.fname}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.lname}</Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>{res.sex}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.mobile}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.city}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {res.next_visit_date?.substring(0, 10)}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellItalic}>
                          {res.reminder_date}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflow}>
                          {res.report_name}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCellOverflowLab}>
                          {res.lab}
                        </Text>
                      </View>
                      <View style={styles.tableColMinWidth}>
                        <Text style={styles.tableCellMinWidth}>6M</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{res.test_date}</Text>
                      </View>
                    </View>
                  ))}
                </>
              ))}
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};
export default TestReportDocument;
