import { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Input,
  Select,
  Option,
  Card,
  Tooltip,
  IconButton,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { useAuth } from "../store/auth.js";
import { useDash, useVisit } from "../store/dashboard";
import axios from "../api/axios";
import { VISIT_LIST, VISIT_DELETE } from "../constants";
import { faEye, faTrashCan, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TABLE_HEAD = [
  "Sr",
  "Id",
  "Date",
  "Next Visit Date",
  "Medicine Reminder",
  "Medicines Count",
  "Reports Count",
  "Actions",
];

export default function VisitModal() {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const setVisitModal = useVisit((state) => state.setVisitModal);
  const visitModal = useVisit((state) => state.visitModal);
  const visitPatientId = useVisit((state) => state.visitPatientId);
  const setVisitPatientId = useVisit((state) => state.setVisitPatientId);
  const visits = useVisit((state) => state.visits);
  const setVisits = useVisit((state) => state.setVisits);
  const resetAuth = useAuth((state) => state.resetAuth);

  const [isDataSet, dataSet] = useState(false);
  const [shouldUpdate, setIntenalTrigger] = useState(0);
  const [count, setCount] = useState();

  const resetModal = () => {
    dataSet(false);
    setIntenalTrigger(0);
  };
  const getVisits = async () => {
    try {
      if (token !== "" && userId !== 0 && visitPatientId !== 0) {
        const response = await axios.get(VISIT_LIST, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { id: visitPatientId },
        });
        console.log(response);
        if (response.status === 200) {
          setVisits(response.data["visits"]);
          dataSet(true);
        } else {
          alert("Failed to fetch visits [1]");
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else if (err.response.status === 401) {
        alert("Session Expired");
        resetAuth();
      } else {
        alert("Failed to fetch visits [2]");
      }
    }
  };
  const handleDelete = async (id) => {
    let text = `Are you sure you want to delete the visit`;
    if (window.confirm(text) == true) {
      try {
        if (token !== "" && userId !== 0 && id !== 0) {
          const response = await axios.post(
            VISIT_DELETE,
            { id: id },
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-tokens": token,
              },
            }
          );
          // console.log(response);
          if (response.status === 200) {
            setIntenalTrigger(shouldUpdate + 1);
          } else {
            alert("Failed to delete visit [1]");
          }
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          alert(err?.response?.data?.message);
        } else {
          alert("Failed to delete visit [2]");
        }
      }
    }
  };
  useEffect(() => {
    if (visitModal) {
      getVisits();
    }
  }, [userId, visitModal, shouldUpdate]);

  return (
    <div className=" md:w-36">
      <Dialog open={visitModal} handler={setVisitModal} size="xl">
        <DialogHeader>
          <span className="w-full text-center">
            Patient Visit details {isDataSet && `(Id:${visitPatientId})`}
          </span>
        </DialogHeader>
        {isDataSet && (
          <DialogBody
            divider
            className="max-h-[45rem] h-[550px] overflow-scroll py-10"
          >
            <Card className="overflow-scroll h-full w-full">
              <table className="w-full min-w-max table-auto text-center">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {visits.map(
                    (
                      {
                        first,
                        review,
                        id,
                        visit_date,
                        next_visit_date,
                        medicine_reminder,
                        prescriptions_count,
                        reports_count,
                      },
                      index
                    ) => {
                      const isLast = index === visits.length - 1;
                      const classes = isLast
                        ? "p-4"
                        : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr
                          key={index}
                          className={
                            review == "True"
                              ? "bg-green-300"
                              : "hover:bg-gray-200"
                          }
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {index + 1} {}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {id}
                              {first == "True" && (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  style={{ color: "orange" }}
                                />
                              )}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {visit_date?.substring(0, 10) || ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {next_visit_date?.substring(0, 10) || ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {medicine_reminder?.substring(0, 10) || ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {prescriptions_count}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {reports_count}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              as="a"
                              href="#"
                              variant="small"
                              color="blue"
                              className="font-medium w-full "
                            >
                              <div className="flex flex-row gap-5 justify-center">
                                <Tooltip
                                  content="View details"
                                  className="z-[10000]"
                                >
                                  <IconButton
                                    color="white"
                                    onClick={() => {
                                      // setPatientModal(true);
                                    }}
                                    ripple={false}
                                  >
                                    <FontAwesomeIcon icon={faEye} size="2x" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip content="Delete" className="z-[10000]">
                                  <IconButton
                                    color="orange"
                                    onClick={() => {
                                      handleDelete(id);
                                    }}
                                    ripple={false}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      size="2x"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
              {visits.length === 0 && (
                <Card className="mt-6 w-full text-center">
                  <CardBody>
                    <Typography variant="h4" color="blue-gray" className="mb-2">
                      No records found
                    </Typography>
                  </CardBody>
                </Card>
              )}
            </Card>
          </DialogBody>
        )}
        <DialogFooter>
          <div className="flex flex-row content-end">
            <Button
              variant="text"
              color="red"
              onClick={() => {
                setVisitModal(false);
              }}
              className="mr-5"
            >
              <span>Cancel</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
}
