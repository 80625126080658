import { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Input,
  Select,
  Option,
  Card,
  Tooltip,
  IconButton,
  CardBody,
  CardFooter,
  Switch,
  Textarea,
  Collapse,
  List,
  ListItem,
  ListItemSuffix,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowRight,
  faSquarePlus,
  faTrash,
  faMedkit,
} from "@fortawesome/free-solid-svg-icons";
import { useDash, useVisit } from "../store/dashboard";
import { useAuth } from "../store/auth.js";
import { MEDICINE_SEARCH } from "../constants";
import axios from "../api/axios";
import { debounce, values } from "lodash";

export default function MedicineList({ firstVisitDate, nextVisitDate }) {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const medicineList = useVisit((state) => state.medicineList);
  const setMedicineList = useVisit((state) => state.setMedicineList);
  const addVisit = useVisit((state) => state.addVisit);
  const setMedicineModal = useDash((state) => state.setMedicineModal);

  const [qs, setQs] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [item, addItem] = useState(false);
  const [medicineData, setMedicineData] = useState({});

  const [errors, setErrors] = useState([]);

  const reqArray = [
    "start_date",
    "end_date",
    "quanitity",
    "frequency",
    "time_frequency",
  ];

  const freqArr = [
    "",
    "Daily",
    "Everyother day",
    "Weekly",
    "Bi-Weekly",
    "Monthly",
    "Custom",
  ];

  const handleItemDelete = (index) => {
    let newArray = [...medicineList];
    let freshArray = newArray.filter(function (med, i) {
      return index !== i;
    });
    setMedicineList(freshArray);
  };
  const handleItemAdd = (e) => {
    console.log("Submitting");
    e.preventDefault();
    let data = medicineData;
    data["medicine_id"] = selectedItem["id"];
    data["name"] = selectedItem["name"];

    let reqData = {
      ...medicineData,
    };
    const checkAllFields = Object.keys(reqData);
    const allFieldsPresent = reqArray.every((fieldName) =>
      checkAllFields.includes(fieldName)
    );
    if (!allFieldsPresent) {
      alert("please fill all the required fields");
    } else {
      setMedicineList([...medicineList, data]);
      setMedicineData({});
      if (firstVisitDate && nextVisitDate) {
        setMedicineData({
          start_date: firstVisitDate,
          end_date: nextVisitDate,
          quanitity: 1,
          time_frequency: 1,
        });
        setErrors([...reqArray].filter((item) => item == "frequency"));
      } else {
        setErrors(reqArray);
      }
      addItem(false);
    }
  };
  const handleChange = (event) => {
    if (reqArray.includes(event.target.name)) {
      if (event.target.value.trim() === "") {
        if (![...errors].includes(event.target.name)) {
          setErrors([...errors, event.target.name]);
        }
      } else {
        setErrors(errors.filter((item) => item !== event.target.name));
      }
    }
    let name = event.target.name;
    const value = event.target.value;
    setMedicineData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSelect = (value, name) => {
    setMedicineData((values) => ({ ...values, [name]: value }));

    if (reqArray.includes(name)) {
      if (value.trim() === "") {
        if (![...errors].includes(name)) {
          setErrors([...errors, name]);
        }
      } else {
        setErrors(errors.filter((item) => item !== name));
      }
    }
  };
  const handleSearch = async () => {
    try {
      if (token !== "" && qs !== "") {
        const response = await axios.get(MEDICINE_SEARCH, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { q: qs },
        });
        console.log(response);
        if (response.status === 200) {
          setSearchList(response.data["medicines"]);
          addItem(false);
        } else {
          alert("Failed to fetch medicines [1]");
        }
      } else if (token !== "" && qs === "") {
        setSearchList([]);
        addItem(false);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else {
        alert("Failed to fetch medicines [2]");
      }
    }
  };
  const handleCreateMedicine = () => {
    setMedicineModal(true);
  };
  useEffect(() => {
    setMedicineList([]);
    if (firstVisitDate && nextVisitDate) {
      setMedicineData({
        start_date: firstVisitDate,
        end_date: nextVisitDate,
        quanitity: 1,
        time_frequency: 1,
      });
      setErrors([...reqArray].filter((item) => item == "frequency"));
    } else {
      setErrors(reqArray);
    }
  }, [addVisit]);
  return (
    <div className="border-2 border-gray-500 border-solid rounded-lg px-5 py-5">
      <IconButton onClick={handleCreateMedicine}>
        <Tooltip content="Create Medicine">
          <FontAwesomeIcon icon={faMedkit} size="2x" />
        </Tooltip>
      </IconButton>
      <Typography className="w-full text-center" variant="h5">
        Medicines
      </Typography>
      {addVisit && (
        <>
          <div className="flex flex-row gap-1">
            <Input
              label={`Search Medicines`}
              icon={<FontAwesomeIcon icon={faSearch} />}
              onChange={(e) => setQs(e.target.value)}
              value={qs}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <IconButton onClick={handleSearch}>
              <FontAwesomeIcon icon={faArrowRight} size="2x" />
            </IconButton>
          </div>
          <Collapse open={item}>
            <Card className="my-4 mx-auto ">
              <CardBody>
                <Typography className="font-semibold">
                  {selectedItem.name || ""}
                </Typography>
                <form
                  className="mt-8 mb-2 flex flex-col gap-3"
                  onSubmit={handleItemAdd}
                >
                  <Input
                    size="lg"
                    label="Start Date"
                    type="date"
                    required
                    pattern="\d{4}-\d{2}-\d{2}"
                    name="start_date"
                    onChange={handleChange}
                    value={medicineData.start_date?.substring(0, 10) || ""}
                    error={errors.includes("start_date")}
                  />
                  <Input
                    size="lg"
                    label="End Date"
                    type="date"
                    required
                    pattern="\d{4}-\d{2}-\d{2}"
                    name="end_date"
                    onChange={handleChange}
                    value={medicineData.end_date?.substring(0, 10) || ""}
                    error={errors.includes("end_date")}
                  />
                  <Input
                    size="lg"
                    label="Quantity"
                    type="number"
                    step=".01"
                    required
                    name="quanitity"
                    onChange={handleChange}
                    value={medicineData.quanitity || ""}
                    error={errors.includes("quanitity")}
                  />
                  <Select
                    label="Frequency"
                    required
                    name="frequency"
                    onChange={(val) => {
                      handleChangeSelect(val, "frequency");
                    }}
                    value={medicineData.frequency || ""}
                    error={errors.includes("frequency")}
                  >
                    <Option value="1">Daily</Option>
                    <Option value="2">Everyother day</Option>
                    <Option value="3">Weekly</Option>
                    <Option value="4">Bi-Weekly</Option>
                    <Option value="5">Monthly</Option>
                    <Option value="6">Custom</Option>
                  </Select>
                  <Input
                    size="lg"
                    label="Time-frequency"
                    required
                    type="number"
                    name="time_frequency"
                    onChange={handleChange}
                    value={medicineData.time_frequency || ""}
                    error={errors.includes("time_frequency")}
                  />
                  <Button type="submit">Add</Button>
                  <Button
                    onClick={() => {
                      addItem(false);
                      setMedicineData({});
                    }}
                    color="red"
                  >
                    Cancel
                  </Button>
                </form>
              </CardBody>
            </Card>
          </Collapse>
          <List>
            {searchList.map((result, index) => (
              <ListItem
                key={index}
                onClick={() => {
                  setSelectedItem(result);
                  addItem(true);
                }}
              >
                {result.name}
                <ListItemSuffix>
                  <FontAwesomeIcon icon={faSquarePlus} size="lg" />
                </ListItemSuffix>
              </ListItem>
            ))}
          </List>
        </>
      )}
      <div className="border-t-2">
        <Typography className="font-normal w-full text-center">
          Selected Medicines
        </Typography>
        <List>
          {medicineList.map((result, index) => (
            <ListItem key={index}>
              {result.name}
              <span className="text-blue-700">
                | SD: {result.start_date} | ED: {result.end_date} | Q:{" "}
                {result.quanitity} | {freqArr[parseInt(result.frequency)]} | TF:{" "}
                {result.time_frequency} |
              </span>
              <ListItemSuffix>
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  color="orange"
                  className="pl-2 pb-4"
                  onClick={() => {
                    handleItemDelete(index);
                  }}
                />
              </ListItemSuffix>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}
