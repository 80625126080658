import { useEffect, useState } from "react";
import axios from "../api/axios";
import { Input, Button } from "@material-tailwind/react";
import { TEST_REPORTS } from "../constants";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import { useAuth } from "../store/auth.js";
import { findIndex, indexOf, result } from "lodash";
import { pdf } from "@react-pdf/renderer";
import TestReportDocument from "./TestReportDocument";

const TestReportsAnalytics = () => {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const today = new Date();
  const currentDate = new Date().toISOString().split("T")[0];
  let tomorrowDate = new Date();
  tomorrowDate.setDate(today.getDate() + 1);
  const [dateData, setDateData] = useState({
    from: currentDate,
    to: tomorrowDate.toISOString().split("T")[0],
  });
  const [testReportAnalytics, setTestReportAnalytics] = useState({});
  const [pdfBlob, setPdfBlob] = useState(false);

  const downloadPdf = async () => {
    const pdfInstance = pdf(
      <TestReportDocument
        testreports={{
          testReportAnalytics,
          dateData,
        }}
      />
    );
    const pdfBlob = await pdfInstance.toBlob();
    setPdfBlob(pdfBlob);
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      const currentDate = new Date();
      const fileName = `testReports_${currentDate.toLocaleString()}.pdf`;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const handleChange = (event) => {
    let name = event.target.name;
    const value = event.target.value;
    setDateData((values) => ({ ...values, [name]: value }));
  };
  const getTestReportDetails = async () => {
    try {
      if (token !== "" && userId !== 0) {
        const response = await axios.get(TEST_REPORTS, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { from: dateData.from, to: dateData.to },
        });
        if (response.status === 200) {
          setTestReportAnalytics(response.data["patients"]);
        } else {
          alert("No server response [1]");
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      } else {
        alert("No server response [2]");
      }
    }
  };
  const calculateSrno = (data, indexMain, indexSub) => {
    // console.log(indexMain, indexSub);
    let count = 0;
    if (indexMain <= 0) {
      return indexSub + 1;
    } else {
      for (var i = 0; i < indexMain; i++) {
        count = count + data[i].length;
        if (i === indexMain - 1) {
          return count + indexSub + 1;
        }
      }
    }
  };
  useEffect(() => {}, []);
  return (
    <>
      <div className="flex flex-col gap-2 w-60 text-center sm:ml-60 md:ml-72 lg:ml-[420px]">
        <Input
          label="From"
          type="date"
          pattern="\d{4}-\d{2}-\d{2}"
          name="from"
          value={dateData.from}
          onChange={handleChange}
        />
        <Input
          label="To"
          type="date"
          pattern="\d{4}-\d{2}-\d{2}"
          name="to"
          value={dateData.to}
          onChange={handleChange}
        />
        <div className="flex flex-row">
          <Button onClick={getTestReportDetails} className="w-16">
            <span className=" text-center">Go</span>
          </Button>
          <Button
            className="bg-gray-700 w-16 ml-[113px]"
            onClick={() => {
              downloadPdf();
            }}
          >
            <span>
              <FontAwesomeIcon icon={faDownload} size="lg" />
            </span>
          </Button>
        </div>
      </div>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Daily</caption>
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Reminder Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Lab
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Frequency
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Initial Test Date
            </td>
          </tr>
        </thead>
        {testReportAnalytics["1"] && (
          <>
            {testReportAnalytics["1"].map((result, index) => (
              <tbody>
                {result.map((res, ind) => (
                  <tr
                    className="border border-slate-600 text-center"
                    key={calculateSrno(testReportAnalytics["1"], index, ind)}
                  >
                    <td className="border border-slate-600 text-center">
                      {calculateSrno(testReportAnalytics["1"], index, ind)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.fname}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.lname}
                    </td>
                    <td className="border border-slate-600 text-center w-5">
                      {res.sex}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.mobile}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.city}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.next_visit_date?.substring(0, 10)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.reminder_date}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate inline-block w-24"
                      title={res.report_name}
                    >
                      {res.report_name}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate w-24"
                      title={res.lab}
                    >
                      {res.lab.substring(0, 12)}...
                    </td>
                    {/* unable to truncate */}
                    <td className="border border-slate-600 text-center w-5">
                      D
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.test_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        )}
      </table>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Every Other Day</caption>
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Reminder Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Lab
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Frequency
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Initial Test Date
            </td>
          </tr>
        </thead>
        {testReportAnalytics["2"] && (
          <>
            {testReportAnalytics["2"].map((result, index) => (
              <tbody>
                {result.map((res, ind) => (
                  <tr
                    className="border border-slate-600 text-center"
                    key={calculateSrno(testReportAnalytics["2"], index, ind)}
                  >
                    <td className="border border-slate-600 text-center">
                      {calculateSrno(testReportAnalytics["2"], index, ind)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.fname}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.lname}
                    </td>
                    <td className="border border-slate-600 text-center w-5">
                      {res.sex}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.mobile}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.city}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.next_visit_date?.substring(0, 10)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.reminder_date}
                    </td>
                    <td
                      className="border border-slate-600 text-center inline-block truncate w-24"
                      title={res.report_name}
                    >
                      {res.report_name}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate w-24"
                      title={res.lab}
                    >
                      {res.lab.substring(0, 12)}...
                    </td>
                    {/* unable to truncate */}
                    <td className="border border-slate-600 text-center">ED</td>
                    <td className="border border-slate-600 text-center">
                      {res.test_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        )}
      </table>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Weekly</caption>
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Reminder Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Lab
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Frequency
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Initial Test Date
            </td>
          </tr>
        </thead>
        {testReportAnalytics["3"] && (
          <>
            {testReportAnalytics["3"].map((result, index) => (
              <tbody>
                {result.map((res, ind) => (
                  <tr
                    className="border border-slate-600 text-center"
                    key={calculateSrno(testReportAnalytics["3"], index, ind)}
                  >
                    <td className="border border-slate-600 text-center">
                      {calculateSrno(testReportAnalytics["3"], index, ind)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.fname}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.lname}
                    </td>
                    <td className="border border-slate-600 text-center w-5">
                      {res.sex}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.mobile}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.city}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.next_visit_date?.substring(0, 10)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.reminder_date}
                    </td>
                    <td
                      className="border border-slate-600 text-center inline-block truncate w-24"
                      title={res.report_name}
                    >
                      {res.report_name}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate w-24"
                      title={res.lab}
                    >
                      {res.lab.substring(0, 12)}...
                    </td>
                    {/* unable to truncate */}
                    <td className="border border-slate-600 text-center">W</td>
                    <td className="border border-slate-600 text-center">
                      {res.test_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        )}
      </table>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Bi Weekly</caption>
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Reminder Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Lab
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Frequency
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Initial Test Date
            </td>
          </tr>
        </thead>
        {testReportAnalytics["4"] && (
          <>
            {testReportAnalytics["4"].map((result, index) => (
              <tbody>
                {result.map((res, ind) => (
                  <tr
                    className="border border-slate-600 text-center"
                    key={calculateSrno(testReportAnalytics["4"], index, ind)}
                  >
                    <td className="border border-slate-600 text-center">
                      {calculateSrno(testReportAnalytics["4"], index, ind)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.fname}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.lname}
                    </td>
                    <td className="border border-slate-600 text-center w-5">
                      {res.sex}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.mobile}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.city}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.next_visit_date?.substring(0, 10)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.reminder_date}
                    </td>
                    <td
                      className="border border-slate-600 text-center inline-block truncate w-24"
                      title={res.report_name}
                    >
                      {res.report_name}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate w-24"
                      title={res.lab}
                    >
                      {res.lab.substring(0, 12)}...
                    </td>
                    {/* unable to truncate */}
                    <td className="border border-slate-600 text-center">BW</td>
                    <td className="border border-slate-600 text-center">
                      {res.test_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        )}
      </table>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Monthly</caption>
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Reminder Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Lab
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Frequency
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Initial Test Date
            </td>
          </tr>
        </thead>
        {testReportAnalytics["5"] && (
          <>
            {testReportAnalytics["5"].map((result, index) => (
              <tbody>
                {result.map((res, ind) => (
                  <tr
                    className="border border-slate-600 text-center"
                    key={calculateSrno(testReportAnalytics["5"], index, ind)}
                  >
                    <td className="border border-slate-600 text-center">
                      {calculateSrno(testReportAnalytics["5"], index, ind)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.fname}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.lname}
                    </td>
                    <td className="border border-slate-600 text-center w-5">
                      {res.sex}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.mobile}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.city}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.next_visit_date?.substring(0, 10)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.reminder_date}
                    </td>
                    <td
                      className="border border-slate-600 text-center inline-block truncate w-24"
                      title={res.report_name}
                    >
                      {res.report_name}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate w-24"
                      title={res.lab}
                    >
                      {res.lab.substring(0, 12)}...
                    </td>
                    {/* unable to truncate */}
                    <td className="border border-slate-600 text-center">M</td>
                    <td className="border border-slate-600 text-center">
                      {res.test_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        )}
      </table>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">Bi Monthly</caption>
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Reminder Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Lab
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Frequency
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Initial Test Date
            </td>
          </tr>
        </thead>
        {testReportAnalytics["6"] && (
          <>
            {testReportAnalytics["6"].map((result, index) => (
              <tbody>
                {result.map((res, ind) => (
                  <tr
                    className="border border-slate-600 text-center"
                    key={calculateSrno(testReportAnalytics["6"], index, ind)}
                  >
                    <td className="border border-slate-600 text-center">
                      {calculateSrno(testReportAnalytics["6"], index, ind)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.fname}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.lname}
                    </td>
                    <td className="border border-slate-600 text-center w-5">
                      {res.sex}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.mobile}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.city}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.next_visit_date?.substring(0, 10)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.reminder_date}
                    </td>
                    <td
                      className="border border-slate-600 text-center inline-block truncate w-24"
                      title={res.report_name}
                    >
                      {res.report_name}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate w-24"
                      title={res.lab}
                    >
                      {res.lab.substring(0, 12)}...
                    </td>
                    {/* unable to truncate */}
                    <td className="border border-slate-600 text-center">BM</td>
                    <td className="border border-slate-600 text-center">
                      {res.test_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        )}
      </table>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">3 Months</caption>
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Reminder Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Lab
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Frequency
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Initial Test Date
            </td>
          </tr>
        </thead>
        {testReportAnalytics["7"] && (
          <>
            {testReportAnalytics["7"].map((result, index) => (
              <tbody>
                {result.map((res, ind) => (
                  <tr
                    className="border border-slate-600 text-center"
                    key={calculateSrno(testReportAnalytics["7"], index, ind)}
                  >
                    <td className="border border-slate-600 text-center">
                      {calculateSrno(testReportAnalytics["7"], index, ind)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.fname}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.lname}
                    </td>
                    <td className="border border-slate-600 text-center w-5">
                      {res.sex}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.mobile}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.city}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.next_visit_date?.substring(0, 10)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.reminder_date}
                    </td>
                    <td
                      className="border border-slate-600 text-center inline-block truncate w-24"
                      title={res.report_name}
                    >
                      {res.report_name}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate w-24"
                      title={res.lab}
                    >
                      {res.lab.substring(0, 12)}...
                    </td>
                    {/* unable to truncate */}
                    <td className="border border-slate-600 text-center">3M</td>
                    <td className="border border-slate-600 text-center">
                      {res.test_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        )}
      </table>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <caption className="font-bold text-xl">6 Months</caption>
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-5">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Reminder Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Report Name
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Lab
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Frequency
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Initial Test Date
            </td>
          </tr>
        </thead>
        {testReportAnalytics["8"] && (
          <>
            {testReportAnalytics["8"].map((result, index) => (
              <tbody>
                {result.map((res, ind) => (
                  <tr
                    className="border border-slate-600 text-center"
                    key={calculateSrno(testReportAnalytics["8"], index, ind)}
                  >
                    <td className="border border-slate-600 text-center">
                      {calculateSrno(testReportAnalytics["8"], index, ind)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.fname}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.lname}
                    </td>
                    <td className="border border-slate-600 text-center w-5">
                      {res.sex}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.mobile}
                    </td>
                    <td className="border border-slate-600 text-center w-20">
                      {res.city}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.next_visit_date?.substring(0, 10)}
                    </td>
                    <td className="border border-slate-600 text-center">
                      {res.reminder_date}
                    </td>
                    <td
                      className="border border-slate-600 text-center inline-block truncate w-24"
                      title={res.report_name}
                    >
                      {res.report_name}
                    </td>
                    <td
                      className="border border-slate-600 text-center truncate w-24"
                      title={res.lab}
                    >
                      {res.lab.substring(0, 12)}...
                    </td>
                    {/* unable to truncate */}
                    <td className="border border-slate-600 text-center">6M</td>
                    <td className="border border-slate-600 text-center">
                      {res.test_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </>
        )}
      </table>
    </>
  );
};
export default TestReportsAnalytics;
