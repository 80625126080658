import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useDash = create((set) => ({
  searchState: 0,
  setSearch: (searchState) => set({ searchState }),
  goState: 0,
  setGo: (goState) => set({ goState }),
  patients: [],
  setPatients: (patients) => set({ patients }),
  searchTrigger: true,
  setTrigger: () => {
    // let value = Math.floor(Math.random() * 10) + 1;
    // console.log(value);
    set((state) => ({ searchTrigger: !state.searchTrigger }));
  },

  medicineModal: false,
  setMedicineModal: (medicineModal) => set({ medicineModal }),

  patientModal: false,
  setPatientModal: (patientModal) => set({ patientModal }),
  patientId: 0,
  setPatientId: (patientId) => set({ patientId }),
  addPatient: false,
  setAddPatient: (addPatient) => set({ addPatient }),
  resetDash: () =>
    set({
      searchState: 0,
      goState: 0,
      patients: [],
      searchTrigger: true,
      patientModal: false,
      patientId: 0,
      addPatient: false,
    }),
}));

export const useVisit = create((set) => ({
  visits: [],
  setVisits: (visits) => set({ visits }),
  visitId: 0,
  setVisitId: (visitId) => set({ visitId }),
  visitModal: false,
  setVisitModal: (visitModal) => set({ visitModal }),
  addVisit: false,
  setAddVisit: (addVisit) => set({ addVisit }),
  visitPatientId: 0,
  setVisitPatientId: (visitPatientId) => set({ visitPatientId }),
  visitDetailsState: false,
  setVisitDetailsState: (visitDetailsState) => set({ visitDetailsState }),
  medicineList: [],
  setMedicineList: (medicineList) => set({ medicineList }),
  reportList: [],
  setReportList: (reportList) => set({ reportList }),
}));
