import { useState, useEffect } from "react";
import { useDash, useVisit } from "../store/dashboard";
import { useAuth } from "../store/auth.js";
import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Input,
  Select,
  Option,
  Card,
  Tooltip,
  IconButton,
  CardBody,
  CardFooter,
  Switch,
  Textarea,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import MedicineList from "./AddMedicine";
import ReportList from "./AddReport";
import { VISIT_ADD } from "../constants";
import axios from "../api/axios";
import { keys, values } from "lodash";
import { prefix } from "@fortawesome/free-solid-svg-icons";
import CreateMedicine from "./CreateMedicine";

export default function VisitModal() {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const setVisitDetailsState = useVisit((state) => state.setVisitDetailsState);
  const visitDetailsState = useVisit((state) => state.visitDetailsState);
  const visitId = useVisit((state) => state.visitId);
  const setVisitId = useVisit((state) => state.setVisitId);
  const addVisit = useVisit((state) => state.addVisit);
  const setAddVisit = useVisit((state) => state.setAddVisit);
  const visitPatientId = useVisit((state) => state.visitPatientId);
  const setVisitPatientId = useVisit((state) => state.setVisitPatientId);
  const visitModal = useVisit((state) => state.visitModal);
  const reportList = useVisit((state) => state.reportList);
  const medicineList = useVisit((state) => state.medicineList);

  const [visitData, setVisitData] = useState({});
  const [hasMedicines, setHasMedicines] = useState(false);
  const [hasReports, sethasReports] = useState(false);
  const [isFirst, setFirst] = useState(false);
  const [forReview, setReview] = useState(false);

  const reqArray = [
    "visit-visit_date",
    "visit-next_visit_date",
    "visit-diagnosis",
  ];
  const [errors, setErrors] = useState([]);

  const resetModal = () => {
    setVisitPatientId(0);
    setAddVisit(false);
    setVisitId(false);
    setVisitDetailsState(false);
    setHasMedicines(false);
    sethasReports(false);
    setFirst(false);
    setReview(false);
    setVisitData({});
    setErrors(reqArray);
  };

  const checkValidation = () => {
    const vData = { ...visitData };
    let filterArray = reqArray.map((ele) => {
      let new_ele = "";
      if (ele.includes("-")) {
        let prefix = ele.split("-")[0];
        new_ele = ele.split("-")[1];
      } else {
        new_ele = ele;
      }
      return { new: new_ele, old: ele };
    });
    filterArray.forEach((ele) => {
      console.log(
        ele,
        vData[ele.new],
        typeof vData[ele.new] === "string",
        vData[ele.new]?.length > 0
      );
      if (
        vData[ele.new] &&
        typeof vData[ele.new] === "string" &&
        vData[ele.new]?.length > 0
      ) {
        console.log(ele);
        setErrors(errors.filter((item) => item !== ele.old));
      }
    });
  };

  const handleChange = (event) => {
    if (reqArray.includes(event.target.name)) {
      if (event.target.value.trim() === "") {
        if (![...errors].includes(event.target.name)) {
          setErrors([...errors, event.target.name]);
        }
      } else {
        setErrors(errors.filter((item) => item !== event.target.name));
      }
    }

    let name = event.target.name;
    let prefix = "";
    if (name.includes("-")) {
      prefix = name.split("-")[0];
      name = name.split("-")[1];
    }
    const value = event.target.value;
    if (prefix == "visit") {
      setVisitData((values) => ({ ...values, [name]: value }));
      // console.log(visitData);
    }
  };
  // const handleChangeSelect = (value, name) => {
  //   let prefix = "";
  //   if (name.includes("-")) {
  //     prefix = name.split("-")[0];
  //     name = name.split("-")[1];
  //   }
  //   if (prefix == "patient") {
  //     setPatientData((values) => ({ ...values, [name]: value }));
  //   } else if (prefix == "address") {
  //     setAddressData((values) => ({ ...values, [name]: value }));
  //   }
  // };
  const handleToggle = (e) => {
    let name = e.target.name;
    if (name == "meds") {
      // console.log(hasMedicines);
      setHasMedicines(!hasMedicines);
    }
    if (name == "reports") {
      // console.log(hasReports);
      sethasReports(!hasReports);
    }
    if (name == "first") {
      // console.log(isFirst);
      setFirst(!isFirst);
    }
    if (name == "review") {
      // console.log(forReview);
      setReview(!forReview);
    }
  };

  const handleVisitNew = async () => {
    let has_old_reports =
      hasReports && reportList.find((val) => val.type === "0");
    try {
      let reqData = {
        visit: { ...visitData },
      };
      reqData["visit"]["first"] = isFirst ? 1 : 0;
      reqData["visit"]["review"] = forReview ? 1 : 0;
      reqData["visit"]["has_prescription"] = hasMedicines ? 1 : 0;
      reqData["visit"]["has_old_prescription"] = 0;
      reqData["visit"]["has_reports"] = hasReports ? 1 : 0;
      reqData["visit"]["has_old_reports"] = has_old_reports ? 1 : 0;
      reqData["visit"]["patient_id"] = visitPatientId;

      if (hasReports) {
        reqData["reports"] = [
          ...reportList.filter((repo) => repo.type === "1"),
        ];
      }
      if (has_old_reports) {
        reqData["old_reports"] = [
          ...reportList.filter((repo) => repo.type === "0"),
        ];
      }
      if (hasMedicines) {
        reqData["prescription"] = [...medicineList];
      }
      console.log(reqData);
      if (token !== "" && userId !== 0 && addVisit) {
        const response = await axios.post(VISIT_ADD, reqData, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
        });
        // console.log(response);
        if (response.status === 200) {
          resetModal();
        } else {
          alert("Failed to add visit [1]");
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert("Failed to add visit [2]");
      }
    }
  };

  const handleDaysToVisit = (event) => {
    let days = parseInt(event.target.value);
    if (visitData?.visit_date?.length > 0 && days > 0) {
      let nextVdate = new Date(visitData.visit_date);
      nextVdate.setDate(nextVdate.getDate() + days);
      setVisitData((values) => ({
        ...values,
        next_visit_date: nextVdate.toISOString()?.substring(0, 10),
      }));
    }
    checkValidation();
  };

  useEffect(() => {
    if (!visitModal) {
      resetModal();
    }
    setErrors(reqArray);
  }, [visitModal]);
  return (
    <Dialog open={visitDetailsState} handler={setVisitDetailsState}>
      <DialogHeader>
        <span className="w-full text-center">
          {addVisit && `New Visit (Patient id:${visitPatientId})`}
        </span>
      </DialogHeader>
      {addVisit && (
        <DialogBody
          divider
          className="sm:h-[30rem] md:h-[35rem] lg:h-[45rem] overflow-scroll py-10"
        >
          <div className="w-full ">
            <div className="mt-8 mb-2 px-10">
              <div className="mb-4 flex flex-col gap-6">
                <Switch
                  name="first"
                  label="First Visit"
                  id="first"
                  ripple={false}
                  onChange={handleToggle}
                />
                <Switch
                  name="review"
                  label="For report review"
                  id="review"
                  ripple={false}
                  onChange={handleToggle}
                />
                <Input
                  size="lg"
                  label="Visit Date"
                  type="date"
                  required
                  pattern="\d{4}-\d{2}-\d{2}"
                  name="visit-visit_date"
                  onChange={handleChange}
                  value={visitData.visit_date?.substring(0, 10) || ""}
                  // className={!isEditSet ? "pointer-events-none" : ""}
                  error={errors.includes("visit-visit_date")}
                />
                <Input
                  size="lg"
                  label="Days to next visit"
                  type="number"
                  name="days-to-next-visit"
                  onChange={handleDaysToVisit}
                  onBlur={checkValidation}
                />
                <Input
                  size="lg"
                  label="Next visit Date"
                  type="date"
                  required
                  pattern="\d{4}-\d{2}-\d{2}"
                  name="visit-next_visit_date"
                  onChange={handleChange}
                  value={visitData.next_visit_date?.substring(0, 10) || ""}
                  // className={!isEditSet ? "pointer-events-none" : ""}
                  error={errors.includes("visit-next_visit_date")}
                />
                <Input
                  size="lg"
                  label="Medicine Reminder"
                  type="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  onChange={handleChange}
                  name="visit-medicine_reminder"
                  value={visitData.medicine_reminder?.substring(0, 10) || ""}
                  icon={
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "orange" }}
                    />
                  }
                />
                <Input
                  size="lg"
                  label="Reffered By (Doctor)"
                  type="text"
                  pattern="\d{4}-\d{2}-\d{2}"
                  name="visit-reffered"
                  onChange={handleChange}
                  value={visitData.reffered || ""}
                  // className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Textarea
                  required
                  size="md"
                  label="Diagnosis (Required)"
                  name="visit-diagnosis"
                  onChange={handleChange}
                  value={visitData.diagnosis || ""}
                  error={errors.includes("visit-diagnosis")}
                />
                <Textarea
                  size="md"
                  label="Previous Complaints"
                  name="visit-previous_complaints"
                  onChange={handleChange}
                  value={visitData.previous_complaints || ""}
                />
                <Textarea
                  size="md"
                  label="Doctor Remarks"
                  name="visit-remarks"
                  onChange={handleChange}
                  value={visitData.remarks || ""}
                />
                <Input
                  size="lg"
                  label="Old pathology report cost"
                  type="number"
                  pattern="\d{4}-\d{2}-\d{2}"
                  name="visit-old_path_reports_cost"
                  onChange={handleChange}
                  value={visitData.old_path_reports_cost || ""}
                  // className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Input
                  size="lg"
                  label="Old radiology report cost"
                  type="number"
                  pattern="\d{4}-\d{2}-\d{2}"
                  name="visit-old_radio_reports_cost"
                  onChange={handleChange}
                  value={visitData.old_radio_reports_cost || ""}
                  // className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Switch
                  name="meds"
                  label="Add Medicines"
                  id="meds"
                  ripple={false}
                  onChange={handleToggle}
                  value={true}
                />

                {hasMedicines && (
                  <MedicineList
                    firstVisitDate={visitData.visit_date}
                    nextVisitDate={visitData.next_visit_date}
                  />
                )}
                <Switch
                  name="reports"
                  label="Add Reports"
                  id="repo"
                  ripple={false}
                  onChange={handleToggle}
                  value={true}
                />
                {hasReports && (
                  <ReportList firstVisitDate={visitData.visit_date} />
                )}
              </div>
              <CreateMedicine />
            </div>
          </div>
        </DialogBody>
      )}
      <DialogFooter>
        <div className="flex flex-row content-end">
          {addVisit && (
            <Button variant="text" onClick={handleVisitNew} className="mr-1">
              Add
            </Button>
          )}
          <Button
            variant="text"
            color="red"
            onClick={resetModal}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
