import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import axios from "../api/axios";
import { LOGIN_URL } from "../constants";
import { useAuth } from "../store/auth.js";
import { useState } from "react";

export default function Login() {
  const setUserId = useAuth((state) => state.setUserId);
  const setToken = useAuth((state) => state.setToken);
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");

  const handleSubmit = async (e) => {
    try {
      const response = await axios.post(
        LOGIN_URL,
        {},
        {
          headers: { "Content-Type": "application/json" },
          //   withCredentials: true,
          auth: {
            username: user.trim(),
            password: pwd,
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        setUserId(1);
        setToken(response.data["token"]);
      } else {
        alert("Login Failed [1]");
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else if (err.response.status === 500) {
        alert("Login Failed [2]");
      } else if (err.response.status === 401) {
        alert("Invalid Login Credentials");
      } else {
        alert("Login Failed [3]");
      }
    }
  };

  return (
    <div className="grid h-screen w-full justify-center content-center">
      <Card color="transparent" shadow={false} className=" w-min h-min mb-40">
        <Typography variant="h4" color="blue-gray" className="text-center">
          Login
        </Typography>
        <Typography color="gray" className="mt-1 font-normal text-center ">
          Enter your details to login.
        </Typography>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-4 flex flex-col gap-6">
            <Input
              size="lg"
              label="Email"
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
            />
            <Input
              type="password"
              size="lg"
              label="Password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
          </div>

          <Button className="mt-6" fullWidth onClick={handleSubmit}>
            Login
          </Button>
          <Typography color="gray" className="mt-4 text-center font-normal">
            <a
              href="#"
              className="font-medium text-blue-500 transition-colors hover:text-blue-700"
            >
              Forget Password
            </a>
          </Typography>
        </form>
      </Card>
    </div>
  );
}
