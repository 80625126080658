import { useEffect, useState } from "react";
import axios from "../api/axios";
import { Input, Button } from "@material-tailwind/react";
import { APPOINTMENT_ANALYTICS } from "../constants";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import { useAuth } from "../store/auth.js";
import { result } from "lodash";
import { pdf } from "@react-pdf/renderer";
import AppointmentDocument from "./AppointmentDocument";

const ViewAppointmentAnalytics = () => {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const today = new Date();
  const currentDate = new Date().toISOString().split("T")[0];
  let tomorrowDate = new Date();
  tomorrowDate.setDate(today.getDate() + 1);
  const [dateData, setDateData] = useState({
    from: currentDate,
    to: tomorrowDate.toISOString().split("T")[0],
  });
  const [appointmentAnalytics, setAppointmentAnalytics] = useState([]);
  const [pdfBlob, setPdfBlob] = useState(false);

  const downloadPdf = async () => {
    const pdfInstance = pdf(
      <AppointmentDocument
        appointments={{
          appointmentAnalytics,
          dateData,
        }}
      />
    );
    const pdfBlob = await pdfInstance.toBlob();
    setPdfBlob(pdfBlob);
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      const currentDate = new Date();
      const fileName = `appointments_${currentDate.toLocaleString()}.pdf`;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const handleChange = (event) => {
    let name = event.target.name;
    const value = event.target.value;
    setDateData((values) => ({ ...values, [name]: value }));
  };
  const getAppointmentDetails = async () => {
    try {
      if (token !== "" && userId !== 0) {
        const response = await axios.get(APPOINTMENT_ANALYTICS, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { from: dateData.from, to: dateData.to },
        });

        if (response.status === 200) {
          setAppointmentAnalytics(response.data["appointments"]);
        } else {
          alert("No server response [1]");
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      } else {
        alert("No server response [2]");
      }
    }
  };
  useEffect(() => {}, []);
  return (
    <>
      <div className="flex flex-col gap-2 w-60 text-center sm:ml-60 md:ml-72 lg:ml-[420px]">
        <Input
          label="From"
          type="date"
          pattern="\d{4}-\d{2}-\d{2}"
          name="from"
          value={dateData.from}
          onChange={handleChange}
        />
        <Input
          label="To"
          type="date"
          pattern="\d{4}-\d{2}-\d{2}"
          name="to"
          value={dateData.to}
          onChange={handleChange}
        />
        <div className="flex flex-row">
          <Button onClick={getAppointmentDetails} className="w-16">
            <span className=" text-center">Go</span>
          </Button>
          <Button className="bg-gray-700 w-16 ml-[113px]" onClick={downloadPdf}>
            <span>
              <FontAwesomeIcon icon={faDownload} size="lg" />
            </span>
          </Button>
        </div>
      </div>

      <table className="border-collapse border border-slate-500  mt-3 w-full">
        <thead>
          <tr>
            <td className="border border-slate-600 text-center font-bold">
              Sr.no
            </td>
            <td className="border border-slate-600 text-center font-bold w-14">
              Id
            </td>
            <td className="border border-slate-600 text-center font-bold">
              First Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Last Name
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Gender
            </td>
            <td className="border border-slate-600 text-center font-bold w-24">
              Mobile
            </td>
            <td className="border border-slate-600 text-center font-bold w-20">
              City
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold">
              Next Visit Date
            </td>
            <td className="border border-slate-600 text-center font-bold w-2">
              Appointment Duration (Days)
            </td>
            <td className="border border-slate-600 text-center font-bold w-14">
              Report Count
            </td>
          </tr>
        </thead>
        {appointmentAnalytics.map((result, index) => (
          <tbody>
            <tr className="border border-slate-600 text-center" key={index}>
              <td className="border border-slate-600 text-center">
                {index + 1}
              </td>
              <td className="border border-slate-600 text-center w-14">
                {result.id}
              </td>
              <td className="border border-slate-600 text-center">
                {result.fname}
              </td>
              <td className="border border-slate-600 text-center">
                {result.lname}
              </td>
              <td className="border border-slate-600 text-center">
                {result.sex}
              </td>
              <td className="border border-slate-600 text-center w-24">
                {result.mobile}
              </td>
              <td className="border border-slate-600 text-center w-20">
                {result.city}
              </td>
              <td className="border border-slate-600 text-center">
                {result.visit_date}
              </td>
              <td className="border border-slate-600 text-center">
                {result.next_visit_date}
              </td>
              <td className="border border-slate-600 text-center w-2">
                {result.days_difference}
              </td>
              <td className="border border-slate-600 text-center w-14">
                {result.report_count}
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </>
  );
};
export default ViewAppointmentAnalytics;
