import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

import ViewAnalyticsDetails from "./ViewAnalyticsDetails";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingBottom: 30,
    paddingTop: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderStyle: "solid",
  },
  tableAppointment: {
    display: "table",
    width: "800",
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    textAlign: "center",
  },
  tableColGender: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0.5,
    textAlign: "center",
  },
  tableColCity: {
    width: "110%",
    borderStyle: "solid",
    borderWidth: 0.5,
    textAlign: "center",
  },

  tableColSrno: {
    width: "60%",
    borderStyle: "solid",
    borderWidth: 0.5,
    textAlign: "center",
  },
  tableCell: {
    width: "100%",
    fontSize: 10,
    padding: 3,
    flexDirection: "col",
    textAlign: "center",
  },
  tableCellItalic: {
    width: "100%",
    fontFamily: "Times-Italic",
    fontWeight: "bold",
    fontSize: 10,
    padding: 3,
    flexDirection: "col",
    textAlign: "center",
  },
  tableCellGender: {
    width: "50%",
    fontSize: 10,
    padding: 3,
    flexDirection: "col",
    textAlign: "center",
  },
  tableCellGenderItalic: {
    width: "50%",
    fontFamily: "Times-Italic",
    fontWeight: "bold",
    fontSize: 10,
    padding: 3,
    flexDirection: "col",
    textAlign: "center",
  },
  tableCellCity: {
    width: "110%",
    fontSize: 10,
    padding: 3,
    flexDirection: "col",
    textAlign: "center",
  },
  tableCellCityItalic: {
    width: "110%",
    fontFamily: "Times-Italic",
    fontWeight: "bold",
    fontSize: 10,
    padding: 3,
    flexDirection: "col",
    textAlign: "center",
  },
  heading: {
    width: 200,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 30,
    marginLeft: 328,
  },
  hospitalhead: {
    width: 200,
    fontWeight: "bold",
    marginLeft: 320,
  },
  rangeDate: {
    width: 300,
    borderStyle: "solid",
    marginBottom: 35,
    fontSize: 15,
    fontWeight: "normal",
  },
  date: {
    width: 200,
    borderStyle: "solid",
    marginBottom: 45,
    fontSize: 15,
    marginLeft: 615,
    fontWeight: "normal",
  },
  image: {
    height: 30,
    width: 100,
  },
});

const AppointmentDocument = ({ appointments }) => {
  const currentDate = new Date();
  const imageData = "logo.jpg";
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page} wrap={true} orientation="landscape">
          <View style={styles.section}>
            <Image style={styles.image} src={imageData} />
            <Text style={styles.hospitalhead}>Shri. Vankatesh Hospital</Text>
            <Text style={styles.heading}>Appointment Analytics</Text>
            <Text style={styles.date}>{currentDate.toLocaleString()}</Text>
            <Text style={styles.rangeDate}>
              Range: From: {appointments.dateData.from} - To:{" "}
              {appointments.dateData.to}
            </Text>
            <View style={styles.tableAppointment}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCellItalic}>Sr.no</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Id</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>First Name</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Last Name</Text>
                </View>
                <View style={styles.tableColGender}>
                  <Text style={styles.tableCellGenderItalic}>Gender</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Mobile</Text>
                </View>
                <View style={styles.tableColCity}>
                  <Text style={styles.tableCellCityItalic}>City</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Next Visit Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>
                    Appointment Duration (Days)
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellItalic}>Report Count</Text>
                </View>
              </View>
              {appointments.appointmentAnalytics.map((result, index) => (
                <View style={styles.tableRow} key={index}>
                  <View style={styles.tableColSrno}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{result.id}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{result.fname}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{result.lname}</Text>
                  </View>
                  <View style={styles.tableColGender}>
                    <Text style={styles.tableCellGender}>{result.sex}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{result.mobile}</Text>
                  </View>
                  <View style={styles.tableColCity}>
                    <Text style={styles.tableCellCity}>{result.city}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{result.visit_date}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {result.next_visit_date}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {result.days_difference}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{result.report_count}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};
export default AppointmentDocument;
