import { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Typography,
  Tooltip,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Input,
  Select,
  Option,
  Checkbox,
} from "@material-tailwind/react";
import {
  PATIENT_DETAILS,
  PATIENT_NEW,
  PATIENT_UPDATE,
  states,
} from "../constants";
import { useAuth } from "../store/auth.js";
import { useDash } from "../store/dashboard";
import axios from "../api/axios";
import { pickBy, isEmpty } from "lodash";
import { prefix } from "@fortawesome/free-solid-svg-icons";

export default function PatientModal() {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const resetAuth = useAuth((state) => state.resetAuth);
  const setPatientModal = useDash((state) => state.setPatientModal);
  const patientModal = useDash((state) => state.patientModal);
  const patientId = useDash((state) => state.patientId);
  const addPatient = useDash((state) => state.addPatient);
  const [patientdata, setPatientData] = useState({});
  const [patientDataProxy, setPatientDataProxy] = useState({});
  const [addressdata, setAddressData] = useState({});
  const [addressDataProxy, setAddressDataProxy] = useState({});
  const setTrigger = useDash((state) => state.setTrigger);
  const [isDataSet, dataSet] = useState(false);
  const [isEditSet, editSet] = useState(false);
  const [errorArr, errSet] = useState(false);

  const [err, setErr] = useState([]);

  const reqArr = [
    "patient-fname",
    "patient-lname",
    "patient-mobile",
    "patient-sex",
    "patient-age",
    "address-locality",
    "address-city",
    "address-pincode",
    "address-state",
  ];

  const callTimeArr = ["Morning", "Afternoon", "Evening", "Night"];
  const prefLangArr = ["Marathi", "Hindi", "English"];

  const resetModal = () => {
    dataSet(false);
    editSet(true);
    setAddressData({});
    setAddressDataProxy({});
    setPatientData({});
    setPatientDataProxy({});
  };
  const handleCheck = (event) => {
    let name = event.target.name;
    let prefix = "";
    if (name.includes("-")) {
      prefix = name.split("-")[0];
      name = name.split("-")[1];
    }
    const value = event.target.checked;
    if (prefix == "patient") {
      setPatientData((values) => ({ ...values, [name]: value }));
    } else if (prefix == "address") {
      setAddressData((values) => ({ ...values, [name]: value }));
    }
  };
  const handleChange = (event) => {
    if (reqArr.includes(event.target.name)) {
      if (event.target.value.trim() === "") {
        if (![...err].includes(event.target.name)) {
          setErr([...err, event.target.name]);
        }
      } else {
        setErr(err.filter((item) => item !== event.target.name));
      }
    }

    let name = event.target.name;
    let prefix = "";
    if (name.includes("-")) {
      prefix = name.split("-")[0];
      name = name.split("-")[1];
    }
    const value = event.target.value;
    if (prefix == "patient") {
      setPatientData((values) => ({ ...values, [name]: value }));
    } else if (prefix == "address") {
      setAddressData((values) => ({ ...values, [name]: value }));
    }
  };

  const handleChangeSelect = (value, name) => {
    if (reqArr.includes(name)) {
      if (value.trim() === "") {
        if (![...err].includes(name)) {
          setErr([...err, name]);
        }
      } else {
        setErr(err.filter((item) => item !== name));
      }
    }
    let prefix = "";
    if (name.includes("-")) {
      prefix = name.split("-")[0];
      name = name.split("-")[1];
    }

    if (prefix == "patient") {
      setPatientData((values) => ({ ...values, [name]: value }));
    } else if (prefix == "address") {
      setAddressData((values) => ({ ...values, [name]: value }));
    }
  };

  const getPatientDetails = async () => {
    try {
      if (token !== "" && userId !== 0 && patientId !== 0) {
        const response = await axios.get(PATIENT_DETAILS, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { id: patientId },
        });
        // console.log(response.data);
        if (response.status === 200) {
          setPatientData(response.data.patient);
          setPatientDataProxy(response.data.patient);
          setAddressData(response.data.address);
          setAddressDataProxy(response.data.address);
          dataSet(true);
          editSet(false);
          setErr([]);
        } else {
          alert("Failed to fetch patients [1]");
        }
      } else if (token !== "" && userId !== 0 && patientId === 0) {
        resetModal();
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else {
        alert("Failed to fetch patients [2]");
      }
    }
  };
  const handlePatientUpdate = async () => {
    try {
      //Diff patient object
      let patientChanged = pickBy(patientdata, function (v, k) {
        return patientDataProxy[k] !== v;
      });
      let addressChanged = pickBy(addressdata, function (v, k) {
        return addressDataProxy[k] !== v;
      });
      let pDataChng = Object.keys(patientChanged);
      let aDataChng = Object.keys(addressChanged);
      let combinedData = {
        ...patientChanged,
        ...addressChanged,
      };
      let pData = [...pDataChng, ...aDataChng];
      let filterArray = reqArr.map((ele) => {
        if (ele.includes("-")) {
          let prefix = ele.split("-")[0];
          ele = ele.split("-")[1];
        }
        return ele;
      });
      const allDataPresent = pData.every((fieldName) => {
        console.log(
          fieldName,
          filterArray.includes(fieldName),
          typeof combinedData[fieldName] === "string"
            ? combinedData[fieldName].trim().length > 0
            : combinedData[fieldName] > 0
        );
        return filterArray.includes(fieldName)
          ? typeof combinedData[fieldName] === "string"
            ? combinedData[fieldName].trim().length > 0
            : combinedData[fieldName] > 0
          : true;
      });

      console.log("All data present", allDataPresent);

      if (!allDataPresent) {
        alert("Please fill all the required fields");
      } else {
        if (
          token !== "" &&
          userId !== 0 &&
          (!isEmpty(addressChanged) || !isEmpty(patientChanged))
        ) {
          let rtnObj = { id: patientId };
          if (!isEmpty(addressChanged)) {
            rtnObj["address"] = addressChanged;
          }
          if (!isEmpty(patientChanged)) {
            rtnObj["patient"] = patientChanged;
          }
          // console.log("rtnObj:", rtnObj);
          const response = await axios.post(PATIENT_UPDATE, rtnObj, {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": token,
            },
          });
          // console.log(response);
          if (response.status === 200) {
            setTrigger();
            resetModal();
            setPatientModal(false);
          } else {
            alert("Failed to update patient [1]");
          }
        } else if (isEmpty(addressChanged) && isEmpty(patientChanged)) {
          alert("No change detected");
          setTrigger();
          resetModal();
          setPatientModal(false);
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert("Failed to update patient [2]");
      }
    }
  };

  const handlePatientNew = async () => {
    try {
      let reqData = {
        ...patientdata,
        ...addressdata,
      };
      // console.log("Req Data:", reqData);

      let filterArray = reqArr.map((ele) => {
        if (ele.includes("-")) {
          let prefix = ele.split("-")[0];
          ele = ele.split("-")[1];
        }
        return ele;
      });

      const checkAllFields = Object.keys(reqData);

      const allFieldsPresent = filterArray.every((fieldName) =>
        checkAllFields.includes(fieldName)
      );
      if (!allFieldsPresent) {
        alert("please fill all the required fields");
      } else {
        if (token !== "" && userId !== 0 && addPatient) {
          const response = await axios.post(PATIENT_NEW, reqData, {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": token,
            },
          });

          if (response.status === 200) {
            setTrigger();
            resetModal();
            setPatientModal(false);
          } else {
            alert("Failed to add patient [1]");
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert("Failed to add patient [2]");
      }
    }
  };

  useEffect(() => {
    getPatientDetails();
    setErr(reqArr);
  }, [patientId, patientModal]);

  return (
    <Dialog open={patientModal} handler={setPatientModal}>
      <DialogHeader>
        <span className="w-full text-center">
          Patient Details {isDataSet ? `:${patientdata.id}` : ""}{" "}
        </span>
      </DialogHeader>
      {isDataSet || addPatient ? (
        <DialogBody
          divider
          className="max-h-[45rem] overflow-scroll py-10 sm:h-[30rem] md:h-[35rem] lg:h-[50rem]"
        >
          <div className="w-full text-center">
            <form className="mt-2 mb-2 px-10">
              <div className="flex w-max mb-2">
                <div className="flex flex-col sm:ml-2 md:ml-4 lg:ml-24">
                  <input
                    type="checkbox"
                    name="patient-chronic"
                    onChange={handleCheck}
                    checked={
                      patientdata.chronic === true ||
                      patientdata.chronic === "True" ||
                      false
                    }
                    className={!isEditSet ? "pointer-events-none" : ""}
                  />
                  <label className="text-green-600">Chronic</label>
                  <input
                    type="checkbox"
                    name="patient-opted"
                    onChange={handleCheck}
                    checked={
                      patientdata.opted === true ||
                      patientdata.opted === "True" ||
                      false
                    }
                    className={!isEditSet ? "pointer-events-none" : ""}
                  />
                  <label className="text-green-600">Box Opted</label>
                </div>
                <div className="flex flex-col ml-56">
                  <input
                    type="checkbox"
                    name="patient-generic_med"
                    onChange={handleCheck}
                    checked={
                      patientdata.generic_med === true ||
                      patientdata.generic_med === "True" ||
                      false
                    }
                    className={!isEditSet ? "pointer-events-none" : ""}
                  />
                  <label className="text-green-600">Generic Medicine</label>
                  <input
                    type="checkbox"
                    name="patient-govt_aid_med"
                    onChange={handleCheck}
                    checked={
                      patientdata.govt_aid_med === true ||
                      patientdata.govt_aid_med === "True" ||
                      false
                    }
                    className={!isEditSet ? "pointer-events-none" : ""}
                  />
                  <label className="text-green-600">Govt Aid Medicine</label>
                </div>
              </div>
              <div className="mb-4 flex flex-col gap-6">
                <Input
                  size="lg"
                  label="First Name"
                  required
                  name="patient-fname"
                  onChange={handleChange}
                  value={patientdata.fname || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  error={err.includes("patient-fname")}
                />
                <Input
                  size="lg"
                  label="Last Name"
                  required
                  name="patient-lname"
                  onChange={handleChange}
                  value={patientdata.lname || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  error={err.includes("patient-lname")}
                />
                <Input
                  size="lg"
                  label="Mobile"
                  required
                  name="patient-mobile"
                  onChange={handleChange}
                  value={patientdata.mobile || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  error={err.includes("patient-mobile")}
                />
                <Input
                  size="lg"
                  label="Age"
                  type="number"
                  required
                  name="patient-age"
                  onChange={handleChange}
                  value={patientdata.age || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  error={err.includes("patient-age")}
                />
                <Input
                  size="lg"
                  label="Email"
                  type="email"
                  name="patient-email"
                  onChange={handleChange}
                  value={patientdata.email || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Select
                  label="Gender"
                  required
                  name="patient-sex"
                  onChange={(val) => {
                    handleChangeSelect(val, "patient-sex");
                  }}
                  value={patientdata.sex || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  error={err.includes("patient-sex")}
                >
                  <Option value="M">Male</Option>
                  <Option value="F">Female</Option>
                  <Option value="T">Transgender</Option>
                </Select>
                <Input
                  size="lg"
                  label="Willingness To Opt"
                  success
                  type="number"
                  min="1"
                  max="10"
                  name="patient-willingness_to_opt"
                  value={patientdata.willingness_to_opt || ""}
                  onChange={handleChange}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  // step="1"
                />
                <Select
                  label="Preffered Language"
                  success
                  name="patient-preferred_language"
                  onChange={(val) => {
                    handleChangeSelect(val, "patient-preferred_language");
                  }}
                  value={patientdata.preferred_language || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                >
                  <Option value="1">Marathi</Option>
                  <Option value="2">Hindi</Option>
                  <Option value="3">English</Option>
                </Select>
                <Select
                  label="Preffered Call Time"
                  success
                  name="patient-preferred_call_time"
                  onChange={(val) => {
                    handleChangeSelect(val, "patient-preferred_call_time");
                  }}
                  value={patientdata.preferred_call_time || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                >
                  <Option value="1">Morning</Option>
                  <Option value="2">Afternoon</Option>
                  <Option value="3">Evening</Option>
                  <Option value="4">Night</Option>
                </Select>
                <Input
                  size="lg"
                  label="Birth Date"
                  type="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  name="patient-birth_date"
                  onChange={handleChange}
                  value={patientdata.birth_date?.substring(0, 10) || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Input
                  size="lg"
                  label="Marriage Anniversary"
                  type="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  name="patient-marriage_date"
                  onChange={handleChange}
                  value={patientdata.marriage_date?.substring(0, 10) || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Input
                  size="lg"
                  label="Caretaker Name"
                  name="patient-carename"
                  onChange={handleChange}
                  value={patientdata.carename || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Input
                  size="lg"
                  label="Caretaker Number"
                  name="patient-carenumber"
                  onChange={handleChange}
                  value={patientdata.carenumber || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                />
                <span className="w-full text-center">
                  <Typography color="black" variant="h5">
                    Address
                  </Typography>
                </span>
                <Input
                  size="lg"
                  label="Flat/House number"
                  type="number"
                  name="address-flatnumber"
                  onChange={handleChange}
                  value={addressdata.flatnumber || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Input
                  size="lg"
                  label="Street Address"
                  name="address-streetadd"
                  onChange={handleChange}
                  value={addressdata.streetadd || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Input
                  size="lg"
                  label="Locality"
                  required
                  name="address-locality"
                  onChange={handleChange}
                  value={addressdata.locality || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  error={err.includes("address-locality")}
                />
                <Input
                  size="lg"
                  label="City"
                  required
                  name="address-city"
                  onChange={handleChange}
                  value={addressdata.city || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  error={err.includes("address-city")}
                />
                <Input
                  size="lg"
                  label="Landmark"
                  name="address-landmark"
                  onChange={handleChange}
                  value={addressdata.landmark || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                />
                <Input
                  size="lg"
                  label="Pincode"
                  required
                  name="address-pincode"
                  onChange={handleChange}
                  value={addressdata.pincode || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  type="number"
                  error={err.includes("address-pincode")}
                />
                <Select
                  label="State"
                  required
                  name="address-state"
                  onChange={(val) => {
                    handleChangeSelect(val, "address-state");
                  }}
                  value={addressdata.state || ""}
                  className={!isEditSet ? "pointer-events-none" : ""}
                  error={err.includes("address-state")}
                >
                  {states.map((state) => (
                    <Option value={state}>{state}</Option>
                  ))}
                </Select>
              </div>
            </form>
          </div>
        </DialogBody>
      ) : (
        <></>
      )}
      <DialogFooter>
        <div className="flex flex-row content-end">
          {isEditSet && !addPatient && (
            <Button
              variant="text"
              color="blue"
              onClick={handlePatientUpdate}
              className="mr-1"
            >
              <span>Save</span>
            </Button>
          )}
          {!isEditSet && !addPatient && (
            <Button
              variant="text"
              color="blue"
              onClick={() => {
                editSet(true);
              }}
              className="mr-1"
            >
              <span>Edit</span>
            </Button>
          )}
          {addPatient && (
            <Button
              variant="text"
              color="blue"
              onClick={handlePatientNew}
              className="mr-1"
            >
              <span>Submit</span>
            </Button>
          )}
          <Button
            variant="text"
            color="red"
            onClick={() => {
              setPatientModal(false);
            }}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
