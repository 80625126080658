import { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Input,
  Select,
  Option,
  Card,
  Tooltip,
  IconButton,
  CardBody,
  CardFooter,
  Switch,
  Textarea,
  Collapse,
  List,
  ListItem,
  ListItemSuffix,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowRight,
  faSquarePlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useVisit } from "../store/dashboard";
import { useAuth } from "../store/auth.js";
import { REPORT_SEARCH } from "../constants";
import axios from "../api/axios";

export default function ReportList({ firstVisitDate }) {
  const token = useAuth((state) => state.token);
  const userId = useAuth((state) => state.userId);
  const reportList = useVisit((state) => state.reportList);
  const setReportList = useVisit((state) => state.setReportList);
  const addVisit = useVisit((state) => state.addVisit);

  const [qs, setQs] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [item, addItem] = useState(false);
  const [reportData, setReportData] = useState({});

  const reqArray = ["type", "test_date", "lab", "frequency"];
  const freqArr = [
    "",
    "Daily",
    "Everyother day",
    "Weekly",
    "Bi-Weekly",
    "Monthly",
    "Bi-Monthly",
    "3 Months",
    "6 Months",
    "Custom",
  ];
  const typeArr = ["Old", "New"];

  const [errors, setErrors] = useState([]);

  const handleItemDelete = (index) => {
    let newArray = [...reportList];
    let freshArray = newArray.filter(function (repo, i) {
      return index !== i;
    });
    setReportList(freshArray);
  };
  const handleItemAdd = (e) => {
    console.log("Submitting");
    e.preventDefault();

    let reqData = {
      ...reportData,
    };
    const checkAllFields = Object.keys(reqData);
    const allFieldsPresent = reqArray.every((fieldName) =>
      checkAllFields.includes(fieldName)
    );
    if (!allFieldsPresent) {
      alert("please fill all the required fields");
    } else {
      let data = reportData;
      data["master_id"] = selectedItem["id"];
      data["name"] = selectedItem["name"];
      setReportList([...reportList, data]);
      setReportData({});
      if (firstVisitDate) {
        setReportData({
          test_date: firstVisitDate,
        });
        setErrors(reqArray.filter((item) => item !== "test_date"));
      } else {
        setErrors(reqArray);
      }
      addItem(false);
    }
  };
  const handleChange = (event) => {
    if (reqArray.includes(event.target.name)) {
      if (event.target.value.trim() === "") {
        if (![...errors].includes(event.target.name)) {
          setErrors([...errors, event.target.name]);
        }
      } else {
        setErrors(errors.filter((item) => item !== event.target.name));
      }
    }

    let name = event.target.name;
    const value = event.target.value;
    setReportData((values) => ({ ...values, [name]: value }));
  };
  const handleChangeSelect = (value, name) => {
    if (reqArray.includes(name)) {
      if (value.trim() === "") {
        if (![...errors].includes(name)) {
          setErrors([...errors, name]);
        }
      } else {
        setErrors(errors.filter((item) => item !== name));
      }
    }

    setReportData((values) => ({ ...values, [name]: value }));
  };
  const handleSearch = async () => {
    try {
      if (token !== "" && qs !== "") {
        const response = await axios.get(REPORT_SEARCH, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { q: qs },
        });
        console.log(response);
        if (response.status === 200) {
          setSearchList(response.data["reports"]);
          addItem(false);
        } else {
          alert("Failed to fetch reports [1]");
        }
      } else if (token !== "" && qs === "") {
        setSearchList([]);
        addItem(false);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else {
        alert("Failed to fetch reports [2]");
      }
    }
  };
  useEffect(() => {
    setReportList([]);
    if (firstVisitDate) {
      setReportData({
        test_date: firstVisitDate,
      });
      setErrors(reqArray.filter((item) => item !== "test_date"));
    } else {
      setErrors(reqArray);
    }
  }, [addVisit]);
  return (
    <div className="border-2 border-gray-500 border-solid rounded-lg px-5 py-5">
      <Typography className="w-full text-center" variant="h5">
        Reports
      </Typography>
      {addVisit && (
        <>
          <div className="flex flex-row gap-1">
            <Input
              label={`Search Reports`}
              icon={<FontAwesomeIcon icon={faSearch} />}
              onChange={(e) => setQs(e.target.value)}
              value={qs}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <IconButton onClick={handleSearch}>
              <FontAwesomeIcon icon={faArrowRight} size="2x" />
            </IconButton>
          </div>
          <Collapse open={item}>
            <Card className="my-4 mx-auto ">
              <CardBody>
                <Typography className="font-semibold">
                  {selectedItem.name || ""}
                </Typography>
                <form
                  className="mt-8 mb-2 flex flex-col gap-3"
                  onSubmit={handleItemAdd}
                >
                  <Select
                    label="Type"
                    required
                    name="type"
                    onChange={(val) => {
                      handleChangeSelect(val, "type");
                    }}
                    value={reportData.type || ""}
                    error={errors.includes("type")}
                  >
                    <Option value="1">New</Option>
                    <Option value="0">Old</Option>
                  </Select>
                  <Input
                    size="lg"
                    label="Test Date"
                    type="date"
                    required
                    pattern="\d{4}-\d{2}-\d{2}"
                    name="test_date"
                    onChange={handleChange}
                    value={reportData.test_date?.substring(0, 10) || ""}
                    error={errors.includes("test_date")}
                  />
                  <Input
                    size="lg"
                    label="Lab"
                    type="text"
                    required
                    name="lab"
                    onChange={handleChange}
                    value={reportData.lab || ""}
                    error={errors.includes("lab")}
                  />
                  <Select
                    label="Frequency"
                    required
                    name="frequency"
                    onChange={(val) => {
                      handleChangeSelect(val, "frequency");
                    }}
                    value={reportData.frequency || ""}
                    error={errors.includes("frequency")}
                    menuProps={{
                      className: "h-32 overflow-scroll-y",
                    }}
                  >
                    <Option value="1">Daily</Option>
                    <Option value="2">Everyother day</Option>
                    <Option value="3">Weekly</Option>
                    <Option value="4">Bi-Weekly</Option>
                    <Option value="5">Monthly</Option>
                    <Option value="6">Bi-Monthly</Option>
                    <Option value="7">3 Months</Option>
                    <Option value="8">6 Months</Option>
                    <Option value="9">Custom</Option>
                  </Select>

                  <Button type="submit">Add</Button>
                  <Button
                    onClick={() => {
                      addItem(false);
                      setReportData({});
                    }}
                    color="red"
                  >
                    Cancel
                  </Button>
                </form>
              </CardBody>
            </Card>
          </Collapse>
          <List>
            {searchList.map((result, index) => (
              <ListItem
                key={index}
                onClick={() => {
                  setSelectedItem(result);
                  addItem(true);
                }}
              >
                {result.name}
                <ListItemSuffix>
                  <FontAwesomeIcon icon={faSquarePlus} size="lg" />
                </ListItemSuffix>
              </ListItem>
            ))}
          </List>
        </>
      )}
      <div className="border-t-2">
        <Typography className="font-normal w-full text-center">
          Selected Reports
        </Typography>
        <List>
          {reportList.map((result, index) => (
            <ListItem key={index}>
              {result.name}
              <span className="text-blue-700">
                || {typeArr[parseInt(result.type)]}
                || {result.test_date}
                || {result.lab}
                || {freqArr[parseInt(result.frequency)]} ||
              </span>
              <ListItemSuffix>
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  color="orange"
                  className="pl-2 pb-4"
                  onClick={() => {
                    handleItemDelete(index);
                  }}
                />
              </ListItemSuffix>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}
