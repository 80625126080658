import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

import ViewAnalyticsDetails from "./ViewAnalyticsDetails";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderStyle: "solid",
  },
  tablePatient: {
    display: "table",
    width: "200",
    marginLeft: "37.5%",
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  tableVisit: {
    display: "table",
    width: "500",
    marginLeft: "18.5%",
    marginTop: "1%",
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  tableMedicine: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
  },
  tableColSrno: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 0.5,
  },

  tableCell: {
    margin: "auto",
    fontSize: 13,
    flexDirection: "col",
  },
  tableCellVisitReal: {
    fontWeight: "bold",
    marginLeft: 175,
  },
  tableCellVisitSystem: {
    fontWeight: "bold",
    marginLeft: 160,
  },
  heading: {
    width: 200,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 30,
    marginLeft: 339,
  },
  hospitalhead: {
    width: 200,
    fontWeight: "bold",
    marginLeft: 302,
  },
  subheadingPatient: {
    fontWeight: "extrabold",
    marginTop: "1%",
    marginBottom: 3,
    marginLeft: 327,
  },
  subheadingVisit: {
    fontWeight: "extrabold",
    marginBottom: 1,
    marginLeft: 342,
    marginTop: "1%",
  },
  subheadingMedicine: {
    fontWeight: "extrabold",
    marginTop: "1%",
    marginBottom: 3,
    marginLeft: 320,
  },
  subheadingReport: {
    fontWeight: "extrabold",
    marginTop: "1%",
    marginBottom: 3,
    marginLeft: 337,
  },
  date: {
    width: 200,
    borderStyle: "solid",
    marginBottom: 45,
    fontSize: 15,
    marginLeft: "73%",
    fontWeight: "normal",
  },
  rangeDate: {
    width: 300,
    borderStyle: "solid",
    marginBottom: 35,
    fontSize: 15,
    fontWeight: "normal",
  },
  image: {
    height: 30,
    width: 100,
    marginLeft: 10,
  },
});

function AnalyticsDocument({ analytics }) {
  const currentDate = new Date();
  const imageData = "logo.jpg";

  useEffect(() => {});

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true} orientation="landscape">
        <View style={styles.section}>
          <Image style={styles.image} src={imageData} />
          <Text style={styles.hospitalhead}>Shri. Vankatesh Hospital</Text>
          <Text style={styles.heading}>Daily Analytics</Text>
          <Text style={styles.date}>{currentDate.toLocaleString()}</Text>
          <Text style={styles.rangeDate}>
            Range: From: {analytics.dateData.from} - To: {analytics.dateData.to}
          </Text>
          <Text style={styles.subheadingPatient}>Patient Analytics</Text>
          <View style={styles.tablePatient}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Total Patients</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {analytics.analyticsPatientData.total_count}
                </Text>
              </View>
            </View>
          </View>

          <Text style={styles.subheadingVisit}>Visit Analytics</Text>
          <View style={styles.tableVisit}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellVisitReal}>
                  Real Visit Analytics
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Review Visits</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>New Visits</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Old Visits</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Visits Count</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {" "}
                  {analytics.analyticsVisitData.review_count}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {" "}
                  {analytics.analyticsVisitData.new_visits}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {analytics.analyticsVisitData.old_visits}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {analytics.analyticsVisitData.real_visit_count}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellVisitSystem}>
                  System Visit Analytics
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>With Medicine</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>With Reports</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Visit Count</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>With Meds-Reports</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}> With No Med No Reports</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {" "}
                  {analytics.analyticsVisitCount.meds_count}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {" "}
                  {analytics.analyticsVisitCount.reports_count}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {analytics.analyticsVisitCount.system_visit_count}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {analytics.analyticsVisitCount.common_count}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {analytics.analyticsVisitCount.count_no_meds_reports}
                </Text>
              </View>
            </View>
          </View>

          <Text style={styles.subheadingMedicine}>Medicine Analytics</Text>
          <View style={styles.tableMedicine}>
            <View style={styles.tableRow}>
              <View style={styles.tableColSrno}>
                <Text style={styles.tableCell}>Sr.no</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Medicine Name</Text>
              </View>
              <View style={styles.tableColSrno}>
                <Text style={styles.tableCell}>Medicine Count</Text>
              </View>
            </View>
            {analytics.medicineAnalytics.map((result, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCell}>{index + 1}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{result.medicine_name}</Text>
                </View>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCell}>{result.medicine_count}</Text>
                </View>
              </View>
            ))}
          </View>

          <Text style={styles.subheadingMedicine}>Report Analytics</Text>
          <View style={styles.tableMedicine}>
            <View style={styles.tableRow}>
              <View style={styles.tableColSrno}>
                <Text style={styles.tableCell}>Sr.no</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Report Name</Text>
              </View>
              <View style={styles.tableColSrno}>
                <Text style={styles.tableCell}>Report Count</Text>
              </View>
            </View>
            {analytics.reportAnalytics.map((result, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCell}>{index + 1}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{result.report_name}</Text>
                </View>
                <View style={styles.tableColSrno}>
                  <Text style={styles.tableCell}>{result.report_count}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}
export default AnalyticsDocument;
