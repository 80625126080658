import React from "react";
import {
  Card,
  CardBody,
  Typography,
  Tooltip,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import {
  Square3Stack3DIcon,
  UserCircleIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";

import { useAnalytics } from "../store/analytics";
import { useState, useEffect } from "react";
import ViewAnalyticsDetails from "./ViewAnalyticsDetails";
import ViewAppointmentAnalytics from "./ViewAppointmentAnalytics";
import MedicineReminderAnalytics from "./MedicineReminderAnalytics";
import TestReportsAnalytics from "./TestReportsAnalytics";

export default function AnalyticsModal() {
  const setAnalyticsModal = useAnalytics((state) => state.setAnalyticsModal);
  const analyticsModal = useAnalytics((state) => state.analyticsModal);

  const currentDate = new Date();

  const tabs = [
    {
      label: "Daily Analytics",
      value: "daily analytics",
    },
    {
      label: "Test Reports",
      value: "test reports",
    },
    {
      label: "Appointments",
      value: "appointments",
    },
    {
      label: "Medicine Reminder",
      value: "medicine reminder",
    },
  ];
  useEffect(() => {});

  return (
    <>
      <Dialog open={analyticsModal} handler={setAnalyticsModal} size="lg">
        <DialogHeader>
          <span className="w-full text-center">Analytics</span>
        </DialogHeader>

        <DialogBody
          divider
          className="max-h-[45rem] overflow-scroll py-10 sm:h-[30rem] md:h-[35rem] lg:h-[50rem]"
        >
          <h3 className="w-full text-center">{currentDate.toLocaleString()}</h3>
          <Tabs value="daily analytics">
            <div className="bg-gradient-to-r from-blue-300 to-light-blue-300">
              <TabsHeader
                className="bg-transparent"
                indicatorProps={{
                  className: "bg-gray-900/10 shadow-none !text-gray-900",
                }}
              >
                {tabs.map(({ label, value }) => (
                  <Tab key={value} value={value}>
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
            </div>
            <TabsBody>
              {tabs.map(({ value }) => (
                <TabPanel key={value} value={value}>
                  {value === "daily analytics" && <ViewAnalyticsDetails />}
                  {value === "test reports" && <TestReportsAnalytics />}
                  {value === "appointments" && <ViewAppointmentAnalytics />}
                  {value === "medicine reminder" && (
                    <MedicineReminderAnalytics />
                  )}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </DialogBody>
        <DialogFooter>
          <div className="flex flex-row content-end">
            <Button
              variant="text"
              color="red"
              onClick={() => {
                setAnalyticsModal(false);
              }}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
}
