import { useState, useEffect } from "react";
import {
  Typography,
  Input,
  Button,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import {
  faSearch,
  faMobileRetro,
  faA,
  faCirclePlus,
  faPieChart,
  faMedkit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDash } from "../store/dashboard";
import { useAuth } from "../store/auth.js";
import Patient from "./Patient";
import axios from "../api/axios";
import { PATIENT_SEARCH } from "../constants";
import PatientModal from "./PatientModel";
import VisitModal from "./ViewVisitsModal";
import VisitDetailsModal from "./VisitDetailsModal";
import AnalyticsModal from "./AnalyticsModal";
import { useAnalytics } from "../store/analytics";
// import { CreateMedicine } from "./CreateMedicine";

export default function Dashboard() {
  const setSearch = useDash((state) => state.setSearch);
  const searchState = useDash((state) => state.searchState);
  const token = useAuth((state) => state.token);
  const setPatients = useDash((state) => state.setPatients);
  const [qs, setQs] = useState("");
  const setTrigger = useDash((state) => state.setTrigger);
  const setAddPatient = useDash((state) => state.setAddPatient);
  const setPatientModal = useDash((state) => state.setPatientModal);
  const setPatientId = useDash((state) => state.setPatientId);
  const setAnalyticsModal = useAnalytics((state) => state.setAnalyticsModal);
  const analyticsModal = useAnalytics((state) => state.analyticsModal);
  const setMedicineModal = useDash((state) => state.setMedicineModal);
  const medicineModal = useDash((state) => state.medicineModal);
  const resetAuth = useAuth((state) => state.resetAuth);

  const searchPatients = async () => {
    try {
      if (token !== "" && qs !== "") {
        const response = await axios.get(PATIENT_SEARCH, {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token,
          },
          params: { q: qs, toggle: searchState },
        });
        console.log(response);
        if (response.status === 200) {
          setPatients(response.data["patients"]);
        } else {
          alert("Failed to fetch patients [1]");
        }
      } else if (token !== "" && qs === "") {
        setTrigger();
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        alert(err?.response?.data?.message);
      } else if (err.response.status === 401) {
        alert("Session Expired");
        resetAuth();
      } else {
        alert("Failed to fetch patients [2]");
      }
    }
  };
  const handleAddPatient = () => {
    setAddPatient(true);
    setPatientModal(true);
    setPatientId(0);
  };
  const handleAddAnalytics = () => {
    setAnalyticsModal(true);
  };
  // const handleCreateMedicine = () => {
  //   setMedicineModal(true);
  // };
  return (
    <div className="grid min-h-screen min-w-full justify-center content-start border-solid">
      <span className="h-min text-center my-5">
        <Typography variant="h3">Patients</Typography>
      </span>
      <div className="flex flex-row w-screen px-14">
        <div className="">
          <IconButton onClick={handleAddAnalytics}>
            <Tooltip content="Analytics">
              <FontAwesomeIcon icon={faPieChart} size="2x" />
            </Tooltip>
          </IconButton>
        </div>
        <span className="flex flex-row gap-x-10 justify-center w-full h-min">
          {/* <div>
            <IconButton onClick={handleCreateMedicine}>
              <Tooltip content="Create Medicine">
                <FontAwesomeIcon icon={faMedkit} size="2x" />
              </Tooltip>
            </IconButton>
          </div> */}
          <div className="w-96">
            <Input
              label="Name or Phone number"
              icon={<FontAwesomeIcon icon={faSearch} />}
              onChange={(e) => setQs(e.target.value)}
              value={qs}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchPatients();
                }
              }}
            />
          </div>
          <div className="flex content-center justify-center">
            <IconButton
              color={searchState !== 0 ? "blue" : "gray"}
              onClick={() => {
                setSearch(1);
              }}
              ripple={false}
            >
              <FontAwesomeIcon icon={faMobileRetro} size="2x" />
            </IconButton>
            <IconButton
              color={searchState !== 1 ? "blue" : "gray"}
              onClick={() => {
                setSearch(0);
              }}
              ripple={false}
            >
              <FontAwesomeIcon icon={faA} size="2x" />
            </IconButton>
          </div>
          <div className="flex content-center w-max">
            <Button
              variant="gradient"
              size="sm"
              className="hidden lg:inline-block md:inline-block sm:inline-block xs:inline-block"
              ripple={false}
              onClick={searchPatients}
            >
              Go
            </Button>
          </div>
        </span>
        <div>
          <IconButton onClick={handleAddPatient}>
            <Tooltip content="Add Patient">
              <FontAwesomeIcon icon={faCirclePlus} size="2x" />
            </Tooltip>
          </IconButton>
        </div>
      </div>
      <div className="w-full max-h-min">
        <Patient />
        <PatientModal />
        <VisitModal />
        <VisitDetailsModal />
        <AnalyticsModal />
        {/* <CreateMedicine /> */}
      </div>
    </div>
  );
}
